import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Check, X, User, LogOut } from 'lucide-react';
import { getUserNotifs, readNotification, deleteNotification, getAccountDetails } from '../utils/accountAPI';
import { Notification, User as UserType } from '../utils/types';
import io from 'socket.io-client';
import styles from './NavBar.module.css';

const Navbar: React.FC = () => {
    const [isNotificationDrawerOpen, setIsNotificationDrawerOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [newNotifications, setNewNotifications] = useState<Notification[]>([]);
    const [notificationFilter, setNotificationFilter] = useState<'all' | 'read' | 'unread'>('all');
    const [unreadCount, setUnreadCount] = useState(0);
    const [user, setUser] = useState<Partial<UserType>>({});
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    // Socket initialization
    useEffect(() => {
        const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
        const socketUrl = apiUrl.endsWith('/api') ? apiUrl.slice(0, -4) : apiUrl;
        const socket = io(socketUrl);
        if (user.id) {
            socket.emit('authenticate', user.id);
        }

        const handleNewNotification = (newNotification: Notification) => {
            setNewNotifications(prev => [newNotification, ...prev].slice(0, 5));
            setNotifications(prev => [newNotification, ...prev]);
            setUnreadCount(prevCount => prevCount + 1);
        };

        socket.on('newNotification', handleNewNotification);

        return () => {
            socket.off('newNotification', handleNewNotification);
            socket.disconnect();
        };
    }, [user.id]);

    // Fetch account details and notifications
    useEffect(() => {
        setIsLoggedIn(!!token);
        if (token) {
            fetchAcc(token);
            fetchNotifications(token);
        }
    }, [token]);

    useEffect(() => {
        const count = notifications.filter(notification => !notification.is_read).length;
        setUnreadCount(count);
    }, [notifications]);

    const fetchAcc = useCallback(async (token: string) => {
        try {
            const response = await getAccountDetails(token);
            setUser(response.data);
        } catch (error) {
            console.error('Failed to fetch account details', error);
        }
    }, []);

    const fetchNotifications = useCallback(async (token: string) => {
        try {
            const response = await getUserNotifs(token);
            setNotifications(response);
        } catch (error) {
            console.error('Failed to fetch notifications', error);
        }
    }, []);

    const handleLogout = useCallback(() => {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        navigate('/');
    }, [navigate]);

    const toggleNotificationDrawer = useCallback(() => {
        setIsNotificationDrawerOpen(prevState => !prevState);
    }, []);

    const handleMarkAsRead = useCallback(async (token: string, notificationId: number) => {
        try {
            await readNotification(token, notificationId);
            setNotifications(prevNotifications =>
                prevNotifications.map(notification =>
                    notification.id === notificationId
                        ? { ...notification, is_read: true }
                        : notification
                )
            );
            setUnreadCount(prevCount => prevCount - 1);
            setNewNotifications(prevNotifications =>
                prevNotifications.filter(notification => notification.id !== notificationId)
            );
        } catch (error) {
            console.error('Failed to mark notification as read', error);
        }
    }, []);

    const handleDeleteNotification = useCallback(async (token: string, notificationId: number) => {
        try {
            await deleteNotification(token, notificationId);
            setNotifications(prevNotifications =>
                prevNotifications.filter(notification => notification.id !== notificationId)
            );
            if (!notifications.find(n => n.id === notificationId)?.is_read) {
                setUnreadCount(prevCount => prevCount - 1);
            }
            setNewNotifications(prevNotifications =>
                prevNotifications.filter(notification => notification.id !== notificationId)
            );
        } catch (error) {
            console.error('Failed to delete notification', error);
        }
    }, [notifications]);

    const handleDismissPopup = useCallback(async (notificationId: number) => {
        await handleMarkAsRead(token as string, notificationId);
    }, [handleMarkAsRead, token]);

    const filteredNotifications = useMemo(() => {
        return notifications.filter(notification => {
            if (notificationFilter === 'all') return true;
            if (notificationFilter === 'read') return notification.is_read;
            if (notificationFilter === 'unread') return !notification.is_read;
            return true;
        });
    }, [notifications, notificationFilter]);

    const menuItems = [
        { name: 'Home', path: '/' },
        { name: 'Upcoming', path: '/upcoming' },
        { name: 'Winners', path: '/winners' },
        { name: 'FAQ', path: '/faq' },
        { name: 'Contact', path: '/contact' },
        // { name: 'Admin', path: '/admin' },
    ];

    return (
        <header className={styles.header}>
            <div className={styles.topBanner}>
                <button onClick={toggleNotificationDrawer} className={styles.logoButton}>
                    <img src='https://ushop.bid/uploads/logo.png' alt="UShop Logo" className={styles.logo} />
                    {unreadCount > 0 && <span className={styles.notificationCounter}>{unreadCount}</span>}
                </button>
                <h1 className={styles.title}>USHOP.BID</h1>

                <div className={styles.accountButtons}>
                    {isLoggedIn ? (
                        <>
                            <Link to="/account" className={styles.accountButton}>
                                <User size={20} />
                                Account
                            </Link>
                            <button onClick={handleLogout} id={styles.navLogout} className={styles.accountButton} >
                                <LogOut size={20} />
                                Logout
                            </button>
                        </>
                    ) : (
                        <>
                            <Link to="/register" id={styles.navLogout} className={styles.accountButton}>Register</Link>
                            <Link to="/login" className={styles.accountButton}>Login</Link>
                        </>
                    )}
                </div>
            </div>

            <nav className={styles.navbar}>
                <ul className={styles.navList}>
                    {menuItems.map((item) => (
                        <li key={item.path} className={styles.navItem}>
                            <Link to={item.path} className={styles.navLink}>
                                {item.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>

            <div className={`${styles.notificationDrawer} ${isNotificationDrawerOpen ? styles.open : ''}`}>
                <button onClick={toggleNotificationDrawer} className={styles.closeButton}>
                    <X size={34} />
                </button>
                <h2 className={styles.notificationTitle}>Notifications</h2>
                <div className={styles.filterButtons}>
                    {['all', 'read', 'unread'].map((filter) => (
                        <button
                            key={filter}
                            onClick={() => setNotificationFilter(filter as 'all' | 'read' | 'unread')}
                            className={`${styles.filterButton} ${notificationFilter === filter ? styles.active : ''}`}
                        >
                            {filter.charAt(0).toUpperCase() + filter.slice(1)}
                        </button>
                    ))}
                </div>

                {filteredNotifications.length === 0 ? (
                    <p>No notifications</p>
                ) : (
                    <ul className={styles.notificationList}>
                        {filteredNotifications.map((notification) => (
                            <li key={notification.id} className={styles.notificationItem}>
                                <div className={styles.notificationContent}>
                                    <p className={styles.notificationMessage}>{notification.message}</p>
                                    <small className={styles.notificationTime}>
                                        {new Date(notification.created_at).toLocaleString()}
                                    </small>
                                </div>
                                <div className={styles.notificationActions}>
                                    {!notification.is_read && (
                                        <button
                                            onClick={() => handleMarkAsRead(token as string, notification.id)}
                                            className={styles.actionButton}
                                            title="Mark as read"
                                        >
                                            <Check size={16} />
                                        </button>
                                    )}
                                    <button
                                        onClick={() => handleDeleteNotification(token as string, notification.id)}
                                        className={styles.actionButton}
                                        title="Delete notification"
                                    >
                                        <X size={16} />
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            {isNotificationDrawerOpen && <div className={styles.overlay} onClick={toggleNotificationDrawer} />}

            <div className={styles.newNotificationsContainer}>
                {newNotifications.map((notification, index) => (
                    <div
                        key={notification.id}
                        className={styles.newNotification}
                        style={{ top: `${20 + index * 80}px` }}
                    >
                        <p className={styles.newNotificationMessage}>{notification.message}</p>
                        <button
                            onClick={() => handleDismissPopup(notification.id)}
                            className={styles.dismissButton}
                            title="Dismiss"
                        >
                            <X size={16} />
                        </button>
                    </div>
                ))}
            </div>
        </header>
    );
};

export default Navbar;