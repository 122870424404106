import React, { useState, useMemo } from 'react';
import { Auction } from '../../utils/types';
import { deleteAuction } from '../../utils/adminAPI';
// import '../styles/admin-lists.css';

interface AuctionListProps {
  auctions: Auction[];
  onEdit: (auction: Auction) => void;
  fetchAuctions: () => void;
}

const AuctionList: React.FC<AuctionListProps> = ({ auctions, onEdit, fetchAuctions }) => {
  const [searchText, setSearchText] = useState('');
  const [sortColumn, setSortColumn] = useState<keyof Auction | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [statusFilter, setStatusFilter] = useState<string | null>(null);
  const token = localStorage.getItem('token'); // or however you're storing the token

  const handleDelete = async (id: number) => {
    
    try {
      if(token) {
        await deleteAuction(token,id);
        fetchAuctions();
      }
      
    } catch (error) {
      console.error('Failed to delete auction', error);
    }
  };

  const handleSort = (column: keyof Auction) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const filteredAndSortedAuctions = useMemo(() => {
    return auctions
      .filter(auction => 
        Object.values(auction).some(value => 
          value?.toString().toLowerCase().includes(searchText.toLowerCase())
        ) && (statusFilter ? auction.status === statusFilter : true)
      )
      .sort((a, b) => {
        if (!sortColumn) return 0;
        if (a[sortColumn] < b[sortColumn]) return sortDirection === 'asc' ? -1 : 1;
        if (a[sortColumn] > b[sortColumn]) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
  }, [auctions, searchText, statusFilter, sortColumn, sortDirection]);

  const clearAll = () => {
    setSearchText('');
    setSortColumn(null);
    setSortDirection('asc');
    setStatusFilter(null);
  };

  return (
    <div className="admin-list">
      <h2>Auction List</h2>
      <div className="admin-list__controls">
        <input
          type="text"
          placeholder="Search auctions"
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          className="admin-list__search"
        />
        <select 
          value={statusFilter || ''} 
          onChange={e => setStatusFilter(e.target.value || null)}
          className="admin-list__filter"
        >
          <option value="">All Statuses</option>
          <option value="upcoming">Upcoming</option>
          <option value="active">Active</option>
          <option value="completed">Completed</option>
        </select>
        <button onClick={clearAll} className="admin-list__clear-btn">Clear filters and sorters</button>
      </div>
      <table className="admin-list__table">
        <thead>
          <tr>
            <th onClick={() => handleSort('id')}>
              ID {sortColumn === 'id' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('product_id')}>
              Product ID {sortColumn === 'product_id' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('current_bid')}>
              Current Bid {sortColumn === 'current_bid' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('start_time')}>
              Start Time {sortColumn === 'start_time' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('countdown_timer')}>
              Countdown Timer {sortColumn === 'countdown_timer' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('status')}>
              Status {sortColumn === 'status' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredAndSortedAuctions.map((auction) => (
            <tr key={auction.id}>
              <td>{auction.id}</td>
              <td>{auction.product_id}</td>
              <td>£{auction.current_bid}</td>
              <td>{new Date(auction.start_time).toLocaleString()}</td>
              <td>{auction.countdown_timer} seconds</td>
              <td>{auction.status}</td>
              <td>
                <button onClick={() => onEdit(auction)} className="admin-list__edit-btn">Edit</button>
                <button onClick={() => handleDelete(auction.id)} className="admin-list__delete-btn">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AuctionList;