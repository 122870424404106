import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import { io } from 'socket.io-client';
import Footer from './components/footer';
import Navbar from './components/NavBar';
import IndexPage from './pages/IndexPage';
import AccountPage from './accounts/AccountPage';
import LoginPage from './accounts/LoginPage';
import RegistrationForm from './accounts/RegisterForm';
import WinnersPage from './pages/Winners';

import AuctionGovernor from './admin/pages/AuctionGovernor';
import HomePage from './admin/pages/HomePage';
import ProductsPage from './admin/pages/ProductsPage';
import AuctionsPage from './admin/pages/AuctionsPage';
import UsersPage from './admin/pages/UsersPage';
import BidsPage from './admin/pages/BidsPage';
import InvoicesPage from './admin/pages/InvoicesPage';
import ReviewsPage from './admin/components/ReviewsComp';

import AuctionDetails from './auction/details';

import { ErrorProvider } from './components/ErrorContext';
import UpcomingAuctions from './auction/upcoming';
import ContactForm from './pages/ContactForm';
import FAQPage from './pages/FAQ';
import LegalInfoPage from './pages/Legal';
import TestPage from './pages/test';
// Initialize Socket.IO client
// const socket = io('http://localhost:4000');
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
const socketUrl = apiUrl.endsWith('/api') ? apiUrl.slice(0, -4) : apiUrl; // Remove '/api' if it exists
const socket = io(socketUrl);

const App: React.FC = () => {
  return (

    <Router>
      <div>
        <Navbar />
        <ErrorProvider>

          <Routes>

            <Route path="/" element={<IndexPage />} />
            <Route path="/register" element={<RegistrationForm />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/test" element={<TestPage />} />

            <Route path="/upcoming" element={<UpcomingAuctions />} />
            <Route path="/winners" element={<WinnersPage />} />
            <Route path="/auction/:id" element={<AuctionDetails />} />

            <Route path="/contact/" element={<ContactForm />} />
            <Route path="/faq/" element={<FAQPage />} />
            <Route path="/info/" element={<LegalInfoPage />} />

            <Route path="/gov" element={<AuctionGovernor />} />
            <Route path="/admin" element={<HomePage />} />
            <Route path="/admin/auctions" element={<AuctionsPage />} />
            <Route path="/admin/products" element={<ProductsPage />} />
            <Route path="/admin/users" element={<UsersPage />} />
            <Route path="/admin/bids" element={<BidsPage />} />
            <Route path="/admin/invoices" element={<InvoicesPage />} />
            {/* <Route path="/admin/winners" element={<AWinnersPage />} /> */}
            <Route path="/admin/reviews" element={<ReviewsPage />} />

          </Routes>
        </ErrorProvider>

      </div>
      <Footer></Footer>

    </Router>

  );
};


export default App;
