// src/pages/UsersPage.tsx
import React, { useState, useEffect } from 'react';
import UserList from '../components/UserList';
import UserForm from '../components/UserForm';
import { getUsers } from '../../utils/adminAPI';
import { User } from '../../utils/types';
const token = localStorage.getItem('token'); // or however you're storing the token

const UsersPage: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]); // State to store user data
  const [selectedUser, setSelectedUser] = useState<User | null>(null); // State to manage selected user for editing

  useEffect(() => {
    fetchUsers(); // Fetch users on component mount
  }, []);

  const fetchUsers = async () => {
    try {
      if(token){
      const response = await getUsers(token); // Fetch users from the backend
      setUsers(response.data);} // Set users in state
    } catch (error) {
      console.error('Failed to fetch users', error);
    }
  };

  const handleEdit = (user: User) => {
    setSelectedUser(user); // Set the user to be edited
  };

  const handleFormSubmit = () => {
    fetchUsers(); // Refresh user list after form submission
    setSelectedUser(null); // Reset selected user
  };

  return (
    <div>
      <h1>User Management</h1>
      <UserList users={users} onEdit={handleEdit} fetchUsers={fetchUsers} /> {/* Display user list */}
      <UserForm selectedUser={selectedUser} onSubmit={handleFormSubmit} /> {/* Display user form */}
    </div>
  );
};

export default UsersPage;
