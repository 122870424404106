// src/components/BidList.tsx
import React from 'react';
import { Bid } from '../../utils/types';
import { deleteBid } from '../../utils/adminAPI';

interface BidListProps {
  bids: Bid[];
  fetchBids: () => void;
}
const token = localStorage.getItem('token'); // or however you're storing the token

const BidList: React.FC<BidListProps> = ({ bids, fetchBids }) => {
  const handleDelete = async (id: number) => {
    try {
      if(token)
      await deleteBid(token,id);
      fetchBids(); // Refresh the bid list after deletion
    } catch (error) {
      console.error('Failed to delete bid', error);
    }
  };

  return (
    <div>
      <h2>Bid List</h2>
      <table>
        <thead>
          <tr>
            <th>User ID</th>
            <th>Auction ID</th>
            <th>Bid Time</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {bids.map((bid) => (
            <tr key={bid.id}>
              <td>{bid.user_id}</td>
              <td>{bid.auction_id}</td>
              <td>{new Date(bid.bid_time).toLocaleString()}</td>
              <td>
                <button onClick={() => handleDelete(bid.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BidList;
