import React, { useState, useMemo } from 'react';
import { Product } from '../../utils/types';
import { deleteProduct } from '../../utils/adminAPI';

interface ProductListProps {
  products: Product[];
  onEdit: (product: Product) => void;
  fetchProducts: () => void;
}

const ProductList: React.FC<ProductListProps> = ({ products, onEdit, fetchProducts }) => {
  const [searchText, setSearchText] = useState('');
  const [sortColumn, setSortColumn] = useState<keyof Product | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const token = localStorage.getItem('token'); // or however you're storing the token

  const handleDelete = async (id: number) => {
    try {
      if(token)
      await deleteProduct(token,id);
      fetchProducts();
    } catch (error) {
      console.error('Failed to delete product', error);
    }
  };

  const handleSort = (column: keyof Product) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const filteredAndSortedProducts = useMemo(() => {
    return products
      .filter(product => 
        Object.values(product).some(value => 
          value?.toString().toLowerCase().includes(searchText.toLowerCase())
        )
      )
      .sort((a, b) => {
        if (!sortColumn) return 0;
        if (! a) return 0;
        if (! b[sortColumn]) return 0;

        if ( a[sortColumn] || 0 < Number(b[sortColumn]) || 0) return sortDirection === 'asc' ? -1 : 1;
        if ( a[sortColumn]||0  >Number(b[sortColumn]) || 0 ) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
  }, [products, searchText, sortColumn, sortDirection]);

  const clearAll = () => {
    setSearchText('');
    setSortColumn(null);
    setSortDirection('asc');
  };

  return (
    <div className="admin-list">
      <h2>Product List</h2>
      <div className="admin-list__controls">
        <input
          type="text"
          placeholder="Search products"
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          className="admin-list__search"
        />
        <button onClick={clearAll} className="admin-list__clear-btn">Clear filters and sorters</button>
      </div>
      <table className="admin-list__table">
        <thead>
          <tr>
            <th onClick={() => handleSort('id')}>
              ID {sortColumn === 'id' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('title')}>
              Title {sortColumn === 'title' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('rrp')}>
              Price (RRP) {sortColumn === 'rrp' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('stock')}>
              Stock {sortColumn === 'stock' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredAndSortedProducts.map((product) => (
            <tr key={product.id}>
              <td>{product.id}</td>
              <td>{product.title}</td>
              <td>£{product.rrp}</td>
              <td>{product.stock}</td>
              <td>{product.description}</td>
              <td>
                <button onClick={() => onEdit(product)} className="admin-list__edit-btn">Edit</button>
                <button onClick={() => handleDelete(product.id)} className="admin-list__delete-btn">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductList;