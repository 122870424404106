import axios, { AxiosResponse } from 'axios';
import {
  User,
  Product,
  Auction,
  Bid,
  Invoice,
  Notification,
  Address,
  Review
} from './types';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000';

const loginConfig = (token: string) => ({
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
});

// Generic admin functions
export const getItems = async (token: string, entityName: string) => {
  return axios.get(`${API_URL}/admin/${entityName}`, loginConfig(token));
};

export const createItem = async (token: string, entityName: string, item: any) => {
  return axios.post(`${API_URL}/admin/${entityName}`, item, loginConfig(token));
};

export const updateItem = async (token: string, entityName: string, id: number, item: any) => {
  return axios.put(`${API_URL}/admin/${entityName}/${id}`, item, loginConfig(token));
};

export const deleteItem = async (token: string, entityName: string, id: number) => {
  return axios.delete(`${API_URL}/admin/${entityName}/${id}`, loginConfig(token));
};

// Users
export const getUsers = async (token: string): Promise<AxiosResponse<User[]>> => {
  return axios.get(`${API_URL}/admin/users`, loginConfig(token));
};

export const getUserById = async (token: string, id: number): Promise<AxiosResponse<User>> => {
  return axios.get(`${API_URL}/admin/users/${id}`, loginConfig(token));
};

export const createUser = async (token: string, userData: Omit<User, 'id'|'reg_date'|'last_login'>): Promise<AxiosResponse<User>> => {
  return axios.post(`${API_URL}/admin/users`, userData, loginConfig(token));
};

export const updateUser = async (token: string, id: number, userData: Partial<User>): Promise<AxiosResponse<User>> => {
  return axios.put(`${API_URL}/admin/users/${id}`, userData, loginConfig(token));
};

export const deleteUser = async (token: string, id: number): Promise<AxiosResponse<void>> => {
  return axios.delete(`${API_URL}/admin/users/${id}`, loginConfig(token));
};

// Products
export const getProducts = async (token: string): Promise<AxiosResponse<Product[]>> => {
  return axios.get(`${API_URL}/admin/products`, loginConfig(token));
};

export const getProductById = async (token: string, id: number): Promise<AxiosResponse<Product>> => {
  return axios.get(`${API_URL}/admin/products/${id}`, loginConfig(token));
};

export const createProduct = async (token: string, productData: Omit<Product, 'id'>): Promise<AxiosResponse<Product>> => {
  return axios.post(`${API_URL}/admin/products`, productData, loginConfig(token));
};

export const updateProduct = async (token: string, id: number, productData: Partial<Product>): Promise<AxiosResponse<Product>> => {
  return axios.put(`${API_URL}/admin/products/${id}`, productData, loginConfig(token));
};

export const deleteProduct = async (token: string, id: number): Promise<AxiosResponse<void>> => {
  return axios.delete(`${API_URL}/admin/products/${id}`, loginConfig(token));
};

// Auctions
export const getAuctions = async (token: string): Promise<AxiosResponse<Auction[]>> => {
  return axios.get(`${API_URL}/admin/auctions`, loginConfig(token));
};

export const getAuctionById = async (token: string, id: number): Promise<AxiosResponse<Auction>> => {
  return axios.get(`${API_URL}/admin/auctions/${id}`, loginConfig(token));
};

export const createAuction = async (token: string, auctionData: Omit<Auction, 'id'>): Promise<AxiosResponse<Auction>> => {
  return axios.post(`${API_URL}/admin/auctions`, auctionData, loginConfig(token));
};

export const updateAuction = async (token: string, id: number, auctionData: Partial<Auction>): Promise<AxiosResponse<Auction>> => {
  return axios.put(`${API_URL}/admin/auctions/${id}`, auctionData, loginConfig(token));
};

export const deleteAuction = async (token: string, id: number): Promise<AxiosResponse<void>> => {
  return axios.delete(`${API_URL}/admin/auctions/${id}`, loginConfig(token));
};

// Bids
export const getBids = async (token: string): Promise<AxiosResponse<Bid[]>> => {
  return axios.get(`${API_URL}/admin/bids`, loginConfig(token));
};

export const getBidById = async (token: string, id: number): Promise<AxiosResponse<Bid>> => {
  return axios.get(`${API_URL}/admin/bids/${id}`, loginConfig(token));
};

export const createBid = async (token: string, bidData: Omit<Bid, 'id'|'user_id'>): Promise<AxiosResponse<Bid>> => {
  return axios.post(`${API_URL}/admin/bids`, bidData, loginConfig(token));
};

export const updateBid = async (token: string, id: number, bidData: Partial<Bid>): Promise<AxiosResponse<Bid>> => {
  return axios.put(`${API_URL}/admin/bids/${id}`, bidData, loginConfig(token));
};

export const deleteBid = async (token: string, id: number): Promise<AxiosResponse<void>> => {
  return axios.delete(`${API_URL}/admin/bids/${id}`, loginConfig(token));
};

// Notifications
export const getNotifications = async (token: string): Promise<AxiosResponse<Notification[]>> => {
  return axios.get(`${API_URL}/admin/notifications`, loginConfig(token));
};

export const getNotificationById = async (token: string, id: number): Promise<AxiosResponse<Notification>> => {
  return axios.get(`${API_URL}/admin/notifications/${id}`, loginConfig(token));
};

export const createNotification = async (token: string, notificationData: Omit<Notification, 'id'>): Promise<AxiosResponse<Notification>> => {
  return axios.post(`${API_URL}/admin/notifications`, notificationData, loginConfig(token));
};

export const updateNotification = async (token: string, id: number, notificationData: Partial<Notification>): Promise<AxiosResponse<Notification>> => {
  return axios.put(`${API_URL}/admin/notifications/${id}`, notificationData, loginConfig(token));
};

export const deleteNotification = async (token: string, id: number): Promise<AxiosResponse<void>> => {
  return axios.delete(`${API_URL}/admin/notifications/${id}`, loginConfig(token));
};

// Addresses
export const getAddresses = async (token: string): Promise<AxiosResponse<Address[]>> => {
  return axios.get(`${API_URL}/admin/addresses`, loginConfig(token));
};

export const getAddressById = async (token: string, id: number): Promise<AxiosResponse<Address>> => {
  return axios.get(`${API_URL}/admin/addresses/${id}`, loginConfig(token));
};

export const createAddress = async (token: string, addressData: Omit<Address, 'id'>): Promise<AxiosResponse<Address>> => {
  return axios.post(`${API_URL}/admin/addresses`, addressData, loginConfig(token));
};

export const updateAddress = async (token: string, id: number, addressData: Partial<Address>): Promise<AxiosResponse<Address>> => {
  return axios.put(`${API_URL}/admin/addresses/${id}`, addressData, loginConfig(token));
};

export const deleteAddress = async (token: string, id: number): Promise<AxiosResponse<void>> => {
  return axios.delete(`${API_URL}/admin/addresses/${id}`, loginConfig(token));
};

// Reviews
export const getReviews = async (token: string): Promise<AxiosResponse<Review[]>> => {
  return axios.get(`${API_URL}/admin/reviews`, loginConfig(token));
};

export const getReviewById = async (token: string, id: number): Promise<AxiosResponse<Review>> => {
  return axios.get(`${API_URL}/admin/reviews/${id}`, loginConfig(token));
};

export const createReview = async (token: string, reviewData: Omit<Review, 'id' | 'created_at'>): Promise<AxiosResponse<Review>> => {
  return axios.post(`${API_URL}/admin/reviews`, reviewData, loginConfig(token));
};

export const updateReview = async (token: string, id: number, reviewData: Partial<Review>): Promise<AxiosResponse<Review>> => {
  return axios.put(`${API_URL}/admin/reviews/${id}`, reviewData, loginConfig(token));
};

export const deleteReview = async (token: string, id: number): Promise<AxiosResponse<void>> => {
  return axios.delete(`${API_URL}/admin/reviews/${id}`, loginConfig(token));
};

// Invoices
export const getInvoices = async (token: string): Promise<AxiosResponse<Invoice[]>> => {
  return axios.get(`${API_URL}/admin/invoices`, loginConfig(token));
};

export const getInvoiceById = async (token: string, id: number): Promise<AxiosResponse<Invoice>> => {
  return axios.get(`${API_URL}/admin/invoices/${id}`, loginConfig(token));
};

export const createInvoice = async (token: string, invoiceData: Omit<Invoice, 'id'>): Promise<AxiosResponse<Invoice>> => {
  return axios.post(`${API_URL}/admin/invoices`, invoiceData, loginConfig(token));
};

export const updateInvoice = async (token: string, id: number, invoiceData: Partial<Invoice>): Promise<AxiosResponse<Invoice>> => {
  return axios.put(`${API_URL}/admin/invoices/${id}`, invoiceData, loginConfig(token));
};

export const deleteInvoice = async (token: string, id: number): Promise<AxiosResponse<void>> => {
  return axios.delete(`${API_URL}/admin/invoices/${id}`, loginConfig(token));
};
