import React, { useState, useEffect } from 'react';
import { registerUser, sendOTP, verifyOTP, checkUserFields } from '../utils/accountAPI';
import { User, Address } from '../utils/types';
import styles from './RegisterForm.module.css';
import { useError } from '../components/ErrorContext'; // Import the Error Context

type UserRegistrationData = Omit<User, 'id' | 'credits' | 'reg_date' | 'is_active'>;
type AddressRegistrationData = Omit<Address, 'id' | 'user_id'>;

const RegistrationPage: React.FC = () => {
  const { showError } = useError(); // Use the context
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<UserRegistrationData & AddressRegistrationData & { confirmPassword: string }>({
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone: '',
    address_type: 'SHIPPING',
    street_address: '',
    city: '',
    state_or_province: '',
    postal_code: '',
    country: '',
    type: 0,
    last_login: new Date().toISOString(),
  });
  const [emailOtp, setEmailOtp] = useState('');
  const [phoneOtp, setPhoneOtp] = useState('');
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [emailOtpAttempts, setEmailOtpAttempts] = useState(0);
  const [phoneOtpAttempts, setPhoneOtpAttempts] = useState(0);
  const [emailTimer, setEmailTimer] = useState(0); // Timer for email OTP
  const [phoneTimer, setPhoneTimer] = useState(0); // Timer for phone OTP

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    if ((name === 'email' && isEmailVerified) || (name === 'phone' && isPhoneVerified)) {
      return; // Prevent changes if already verified
    }
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateStep = (currentStep: number): boolean => {
    switch (currentStep) {
      case 1:
        return (
          formData.username !== '' &&
          formData.first_name !== '' &&
          formData.last_name !== '' &&
          formData.email !== '' &&
          formData.password !== '' &&
          formData.confirmPassword !== '' &&
          formData.password === formData.confirmPassword
        );
      case 2:
        return formData.phone !== '';
      case 3:
        return (
          formData.street_address !== '' &&
          formData.city !== '' &&
          formData.country !== ''
        );
      default:
        return false;
    }
  };

  const checkExistingFields = async () => {
    try {
      const response = await checkUserFields({
        username: formData.username,
        email: formData.email,
        phone: formData.phone,
      });

      if (response.exists) {
        const fields = response.fields.join(', ');
        showError(`The following field(s) are already taken: ${fields}. Please choose different values.`, 'warning'); // Use showError
        return false;
      }
      return true;
    } catch (err) {
      showError('An error occurred while checking your information. Please try again.'); // Use showError
      return false;
    }
  };

  const verifyOTPs = async () => {
    if (!isEmailVerified) {
      try {
        const emailVerified = await verifyOTP(formData.email, emailOtp, 'email');
        if (!emailVerified) {
          showError('Email OTP verification failed. Please check and try again.'); // Use showError
          return false;
        }
        setIsEmailVerified(true);
        setEmailTimer(0); // Reset the timer
        setEmailOtpAttempts(0); // Reset attempts
      } catch (err) {
        showError('An error occurred during email verification. Please try again.'); // Use showError
        return false;
      }
    }

    if (!isPhoneVerified) {
      try {
        const phoneVerified = await verifyOTP(formData.phone || '', phoneOtp, 'phone');
        if (!phoneVerified) {
          showError('Phone OTP verification failed. Please check and try again.'); // Use showError
          return false;
        }
        setIsPhoneVerified(true);
        setPhoneTimer(0); // Reset the timer
        setPhoneOtpAttempts(0); // Reset attempts
      } catch (err) {
        showError('An error occurred during phone verification. Please try again.'); // Use showError
        return false;
      }
    }

    return true;
  };

  const handleNextStep = async () => {
    if (validateStep(step)) {
      if (step === 1) {
        const fieldsAvailable = await checkExistingFields();
        if (!fieldsAvailable) return;
      } else if (step === 2) {
        const otpsVerified = await verifyOTPs();
        if (!otpsVerified) return;
        const fieldsAvailable = await checkExistingFields();
        if (!fieldsAvailable) return;
      }
      if (step < 3) setStep((prev) => prev + 1);
    } else {
      showError('Please fill in all required fields correctly before proceeding.'); // Use showError
    }
  };

  const handlePrevStep = () => {
    if (step > 1) setStep((prev) => prev - 1);
  };

  const handleSendOTP = async (type: 'email' | 'phone') => {
    const attempts = type === 'email' ? emailOtpAttempts : phoneOtpAttempts;
    const timer = type === 'email' ? emailTimer : phoneTimer;

    if (attempts >= 3) {
      showError(`You've exceeded the maximum number of OTP requests for ${type}. Please try again later.`); // Use showError
      return;
    }

    if (timer > 0) {
      showError(`Please wait ${timer} seconds before requesting a new OTP.`); // Use showError
      return;
    }

    try {
      const contact = type === 'email' ? formData.email : formData.phone;
      await sendOTP(contact || '', type);
      showError(`${type.charAt(0).toUpperCase() + type.slice(1)} OTP sent successfully!`); // Use showError
      if (type === 'email') {
        setEmailOtpAttempts(prev => prev + 1);
        setEmailTimer(60); // Start the 60-second timer
      } else {
        setPhoneOtpAttempts(prev => prev + 1);
        setPhoneTimer(60); // Start the 60-second timer
      }
    } catch (err) {
      showError(`Failed to send OTP to ${type}. Please try again.`); // Use showError
    }
  };

  // Timer effect for email and phone OTP
  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (emailTimer > 0) {
      timerId = setInterval(() => setEmailTimer(prev => prev - 1), 1000);
    }
    return () => clearInterval(timerId);
  }, [emailTimer]);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (phoneTimer > 0) {
      timerId = setInterval(() => setPhoneTimer(prev => prev - 1), 1000);
    }
    return () => clearInterval(timerId);
  }, [phoneTimer]);

  const handleVerifyOTP = async (type: 'email' | 'phone') => {
    try {
      const otp = type === 'email' ? emailOtp : phoneOtp;
      const contact = type === 'email' ? formData.email : formData.phone;
      const isVerified = await verifyOTP(contact || '', otp, type);
      if (isVerified) {
        if (type === 'email') setIsEmailVerified(true);
        else setIsPhoneVerified(true);
      } else {
        showError('Invalid OTP. Please try again.'); // Use showError
      }
    } catch (err) {
      showError(`Failed to verify ${type}. Please try again.`); // Use showError
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateStep(3)) {
      showError('Please fill in all required fields correctly.'); // Use showError
      return;
    }

    try {
      const userData: UserRegistrationData = {
        username: formData.username,
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        phone: formData.phone,
        password: formData.password,
        type: formData.type,
        last_login: formData.last_login,
      };

      const addressData: AddressRegistrationData = {
        address_type: formData.address_type,
        street_address: formData.street_address,
        city: formData.city,
        state_or_province: formData.state_or_province,
        postal_code: formData.postal_code,
        country: formData.country,
      };

      await registerUser(userData, addressData);
      showError('Registration successful! You can now log in.', 'info'); // Use showError
      window.location.href = '/login';

    } catch (err) {
      showError('Registration failed. Please try again.'); // Use showError
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className={styles.formStep}>
            <h2 className={styles.formSubtitle}>User Details</h2>
            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="username">Username</label>
              <input
                className={styles.formInput}
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                placeholder="Choose a username"
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="first_name">First Name</label>
              <input
                className={styles.formInput}
                type="text"
                id="first_name"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                placeholder="Enter your first name"
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="last_name">Last Name</label>
              <input
                className={styles.formInput}
                type="text"
                id="last_name"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                placeholder="Enter your last name"
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="email">Email</label>
              <input
                className={styles.formInput}
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter your email"
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="password">Password</label>
              <input
                className={styles.formInput}
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                placeholder="Create a password"
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="confirmPassword">Confirm Password</label>
              <input
                className={styles.formInput}
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                placeholder="Confirm your password"
                required
              />
            </div>
          </div>
        );
      case 2:
        return (
          <div className={styles.formStep}>
            <h2 className={styles.formSubtitle}>Verification</h2>
            <div className={styles.formGroup}>
              <label className={styles.formLabel}>Email</label>
              <div className={styles.formInputGroup}>
                <input
                  className={styles.formInput}
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  readOnly={isEmailVerified}
                />
                <button
                  type="button"
                  className={`${styles.button} ${styles.buttonSecondary}`}
                  onClick={() => handleSendOTP('email')}
                  disabled={isEmailVerified || emailOtpAttempts >= 3 || emailTimer > 0}
                >
                  {emailTimer > 0 ? `${emailTimer} s` : emailOtpAttempts >= 3 ? 'Max Attempts' : 'Send OTP'}
                </button>
              </div>
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="emailOtp">Email OTP</label>
              <div className={styles.formInputGroup}>
                <input
                  className={styles.formInput}
                  type="text"
                  id="emailOtp"
                  value={emailOtp}
                  onChange={(e) => setEmailOtp(e.target.value)}
                  placeholder="Enter Email OTP"
                  required
                  disabled={isEmailVerified}
                />
                <button
                  type="button"
                  className={`${styles.button} ${isEmailVerified ? styles.buttonVerified : styles.buttonSecondary}`}
                  onClick={() => handleVerifyOTP('email')}
                  disabled={isEmailVerified}
                >
                  {isEmailVerified ? 'Verified' : 'Verify'}
                </button>
              </div>
              {isEmailVerified && <span className={styles.verifiedBadge}>Verified</span>}
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="phone">Phone Number</label>
              <div className={styles.formInputGroup}>
                <input
                  className={styles.formInput}
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  placeholder="Enter your phone number"
                  required
                  readOnly={isPhoneVerified}
                />
                <button
                  type="button"
                  className={`${styles.button} ${styles.buttonSecondary}`}
                  onClick={() => handleSendOTP('phone')}
                  disabled={isPhoneVerified || phoneOtpAttempts >= 3 || phoneTimer > 0}
                >
                  {phoneTimer > 0 ? `${phoneTimer} s` : phoneOtpAttempts >= 3 ? 'Max Attempts' : 'Send OTP'}
                </button>
              </div>
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="phoneOtp">Phone OTP</label>
              <div className={styles.formInputGroup}>
                <input
                  className={styles.formInput}
                  type="text"
                  id="phoneOtp"
                  value={phoneOtp}
                  onChange={(e) => setPhoneOtp(e.target.value)}
                  placeholder="Enter Phone OTP"
                  required
                  disabled={isPhoneVerified}
                />
                <button
                  type="button"
                  className={`${styles.button} ${isPhoneVerified ? styles.buttonVerified : styles.buttonSecondary}`}
                  onClick={() => handleVerifyOTP('phone')}
                  disabled={isPhoneVerified}
                >
                  {isPhoneVerified ? 'Verified' : 'Verify'}
                </button>
              </div>
              {isPhoneVerified && <span className={styles.verifiedBadge}>Verified</span>}
            </div>
          </div>
        );
      case 3:
        return (
          <div className={styles.formStep}>
            <h2 className={styles.formSubtitle}>Shipping Address</h2>
            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="street_address">Street Address</label>
              <input
                className={styles.formInput}
                type="text"
                id="street_address"
                name="street_address"
                value={formData.street_address}
                onChange={handleInputChange}
                placeholder="Enter your street address"
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="city">City</label>
              <input
                className={styles.formInput}
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                placeholder="Enter your city"
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="state_or_province">State/Province</label>
              <input
                className={styles.formInput}
                type="text"
                id="state_or_province"
                name="state_or_province"
                value={formData.state_or_province}
                onChange={handleInputChange}
                placeholder="Enter your state or province"
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="postal_code">Postal Code</label>
              <input
                className={styles.formInput}
                type="text"
                id="postal_code"
                name="postal_code"
                value={formData.postal_code}
                onChange={handleInputChange}
                placeholder="Enter your postal code"
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel} htmlFor="country">Country</label>
              <input
                className={styles.formInput}
                type="text"
                id="country"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                placeholder="Enter your country"
                required
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.registrationContainer}>
      <h1 className={styles.registrationTitle}>Create your account</h1>
      <form onSubmit={handleSubmit}>
        <div className={styles.stepIndicators}>
          {[1, 2, 3].map((i) => (
            <div
              key={i}
              className={`${styles.stepIndicator} ${step === i ? styles.active : ''} ${step > i ? styles.completed : ''
                }`}
            >
              {step > i ? '✓' : i}
            </div>
          ))}
        </div>
        {renderStep()}
        <div className={styles.formNavigation}>
          {step > 1 && (
            <button type="button" onClick={handlePrevStep} className={`${styles.button} ${styles.buttonPrev}`}>
              Previous
            </button>
          )}
          {step < 3 ? (
            <button type="button" onClick={handleNextStep} className={`${styles.button} ${styles.buttonNext}`}>
              Next
            </button>
          ) : (
            <button type="submit" className={`${styles.button} ${styles.buttonSubmit}`}>
              Register
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default RegistrationPage;
