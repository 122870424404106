import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface PolicySection {
  title: string;
  content: string[];
}

const policyData: PolicySection[] = [
  {
    title: "Terms of Service",
    content: [
      "1. Acceptance of Terms: By accessing and using UShop's services, you agree to be bound by these Terms of Service.",
      "2. User Accounts: You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.",
      "3. Bidding: All bids are final and cannot be retracted. By placing a bid, you are committing to buy the item if you win the auction.",
      "4. Payments: Winners must complete payment within 24 hours of the auction ending. Failure to do so may result in account suspension.",
      "5. Item Description: While we strive for accuracy, UShop is not responsible for any discrepancies in item descriptions or conditions.",
      "6. Termination: UShop reserves the right to terminate or suspend accounts at our discretion, without prior notice.",
      "7. Changes to Terms: UShop may modify these terms at any time. Continued use of the service after changes constitutes acceptance of the new terms."
    ]
  },
  {
    title: "Privacy Policy",
    content: [
      "1. Information Collection: We collect personal information you provide when creating an account or making a purchase, as well as usage data and cookies.",
      "2. Use of Information: Your information is used to process transactions, personalize your experience, and improve our services.",
      "3. Information Sharing: We do not sell your personal information. We may share data with service providers or as required by law.",
      "4. Data Security: We implement various security measures to protect your personal information.",
      "5. Your Rights: You have the right to access, correct, or delete your personal data. Contact us to exercise these rights.",
      "6. Third-Party Links: Our website may contain links to third-party sites. We are not responsible for their privacy practices.",
      "7. Changes to Policy: We may update this policy periodically. Check this page for the latest information."
    ]
  },
  {
    title: "Refund Policy",
    content: [
      "1. Eligibility: Items are eligible for refund within 30 days of delivery, provided they are unused and in original condition.",
      "2. Process: To initiate a refund, contact our customer service team with your order number and reason for return.",
      "3. Shipping Costs: Original shipping costs are non-refundable. Return shipping costs are the responsibility of the customer unless the item is defective.",
      "4. Defective Items: If an item is defective, we will cover return shipping and send a replacement or issue a full refund.",
      "5. Processing Time: Refunds are typically processed within 5-10 business days after we receive the returned item.",
      "6. Method of Refund: Refunds will be issued to the original payment method used for the purchase.",
      "7. Exceptions: Some items, such as personalized goods or perishables, may not be eligible for return. Check the product description for details."
    ]
  },
  {
    title: "Shipping Information",
    content: [
      "1. Shipping Methods: We offer standard and express shipping options. Available methods will be shown at checkout.",
      "2. Shipping Costs: Shipping costs are calculated based on the item's size, weight, and your location. Costs are displayed before purchase confirmation.",
      "3. Processing Time: Orders are typically processed within 1-3 business days before shipping.",
      "4. Delivery Time: Standard shipping usually takes 5-10 business days. Express shipping takes 2-5 business days.",
      "5. International Shipping: We ship worldwide. Please note that international orders may be subject to import duties and taxes.",
      "6. Tracking: Once your order ships, you will receive a tracking number via email.",
      "7. Lost or Damaged Packages: If your package is lost or arrives damaged, please contact our customer service team within 7 days of the expected delivery date."
    ]
  }
];

const LegalInfoPage: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleSection = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Legal Information</h1>
      <div style={styles.policyList}>
        {policyData.map((section, index) => (
          <div key={index} style={styles.policySection}>
            <div 
              style={styles.policyHeader} 
              onClick={() => toggleSection(index)}
            >
              <h2 style={styles.policyTitle}>{section.title}</h2>
              {openIndex === index ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
            </div>
            {openIndex === index && (
              <div style={styles.policyContent}>
                {section.content.map((paragraph, pIndex) => (
                  <p key={pIndex} style={styles.paragraph}>{paragraph}</p>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <div style={styles.disclaimer}>
        <p>Last updated: {new Date().toLocaleDateString()}</p>
        <p>This document is for informational purposes only and does not constitute legal advice. For specific legal inquiries, please contact our legal department.</p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '900px',
    margin: '0 auto',
    padding: '40px 20px',
    fontFamily: 'Roboto, sans-serif',
  } as React.CSSProperties,
  title: {
    fontSize: '36px',
    color: '#e53e3e',
    textAlign: 'center',
    marginBottom: '40px',
  } as React.CSSProperties,
  policyList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  } as React.CSSProperties,
  policySection: {
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  } as React.CSSProperties,
  policyHeader: {
    backgroundColor: '#f8f8f8',
    padding: '20px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: 'background-color 0.3s ease',
  } as React.CSSProperties,
  policyTitle: {
    margin: 0,
    fontSize: '24px',
    color: '#e53e3e',
  } as React.CSSProperties,
  policyContent: {
    padding: '20px',
    backgroundColor: '#ffffff',
  } as React.CSSProperties,
  paragraph: {
    marginBottom: '15px',
    lineHeight: '1.6',
  } as React.CSSProperties,
  disclaimer: {
    marginTop: '40px',
    fontSize: '14px',
    color: '#666',
    textAlign: 'center',
  } as React.CSSProperties,
};

export default LegalInfoPage;