// src/pages/AuctionsPage.tsx
import React, { useState, useEffect } from 'react';
import AuctionList from '../components/AuctionList';
import AuctionForm from '../components/AuctionForm';
import { getAuctions } from '../../utils/adminAPI';
import { Auction } from '../../utils/types';

const AuctionsPage: React.FC = () => {
  const [auctions, setAuctions] = useState<Auction[]>([]); // State to store auction data
  const [selectedAuction, setSelectedAuction] = useState<Auction | null>(null); // State to manage selected auction for editing
  const token = localStorage.getItem('token'); // or however you're storing the token

  useEffect(() => {
    fetchAuctions(); // Fetch auctions on component mount
  }, []);

  const fetchAuctions = async () => {
    try {
      if(token){
      const response = await getAuctions(token); // Fetch auctions from the backend
      setAuctions(response.data); }// Set auctions in state
    } catch (error) {
      console.error('Failed to fetch auctions', error);
    }
  };

  const handleEdit = (auction: Auction) => {
    setSelectedAuction(auction); // Set the auction to be edited
  };

  const handleFormSubmit = () => {
    fetchAuctions(); // Refresh auction list after form submission
    setSelectedAuction(null); // Reset selected auction
  };

  return (
    <div>
      <h1>Manage Auctions</h1>
      <AuctionForm selectedAuction={selectedAuction} onSubmit={handleFormSubmit} /> {/* Display auction form */}
      <AuctionList auctions={auctions} onEdit={handleEdit} fetchAuctions={fetchAuctions} /> {/* Display auction list */}

    </div>
  );
};

export default AuctionsPage;
