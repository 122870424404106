import React, { useState, useEffect } from 'react';
import { Auction, Product } from '../../utils/types';
import { createAuction, updateAuction, getProducts } from '../../utils/adminAPI';
// import './inv.css'; // Ensure the path is correct based on your directory structure

interface AuctionFormProps {
  selectedAuction: Auction | null;
  onSubmit: () => void;
}

const AuctionForm: React.FC<AuctionFormProps> = ({ selectedAuction, onSubmit }) => {
  const [formData, setFormData] = useState<Omit<Auction, 'id'>>({
    product_id: 0,
    umin: 0,
    start_time: '',
    countdown_timer: 0,
    current_bid: 0,
    status: 'upcoming',
    bmax: 0
  });

  const [products, setProducts] = useState<Product[]>([]);
  const token = localStorage.getItem('token'); // or however you're storing the token

  useEffect(() => {
    fetchProducts();

    if (selectedAuction) {
      // When editing, include all fields including the id
      setFormData(selectedAuction);
    } else {
      // When creating, reset the form without an id
      setFormData({
        product_id: 0,
        umin: 0,
        start_time: '',
        countdown_timer: 0,
        current_bid: 0,
        status: 'upcoming',
        bmax: 0
      });
    }
  }, [selectedAuction]);

  const fetchProducts = async () => {
    try {
      if(token){
      const response = await getProducts(token);
      setProducts(response.data);}
    } catch (error) {
      console.error('Error fetching products', error);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    const parsedValue = ['product_id', 'umin', 'countdown_timer', 'current_bid', 'bmax'].includes(name)
      ? parseInt(value, 10)
      : value;
    
    setFormData(prevData => ({
      ...prevData,
      [name]: parsedValue,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if(token){
      if (selectedAuction) {
        // For updates, we need to include the id
        await updateAuction(token,selectedAuction.id, formData as Auction);
      } else {
        // For new auctions, we don't include an id
        await createAuction(token,formData);
      }
      onSubmit();
    }
    } catch (error) {
      console.error('Error saving auction', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <h3>{selectedAuction ? 'Update Auction' : 'Create Auction'}</h3>
        <label htmlFor="product_id">Product:</label>
        <select
          id="product_id"
          name="product_id"
          value={formData.product_id}
          onChange={handleChange}
          required
        >
          <option value="">Select a product</option>
          {products.map((product) => (
            <option key={product.id} value={product.id}>
              {product.id} - {product.title}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor="umin">Minimum Bids(pence):</label>
        <input
          type="number"
          id="umin"
          name="umin"
          value={formData.umin}
          onChange={handleChange}
          placeholder="Minimum Bids"
          required
        />
      </div>
      <div>
        <label htmlFor="bmax">Bmax(pence):</label>
        <input
          type="number"
          id="bmax"
          name="bmax"
          value={formData.bmax}
          onChange={handleChange}
          placeholder="Max B Bids"
          required
        />
      </div>
      <div>
        <label htmlFor="start_time">Start Time:</label>
        <input
          type="datetime-local"
          id="start_time"
          name="start_time"
          value={formData.start_time}
          onChange={handleChange}
          placeholder="Start Time"
          required
        />
      </div>
      <div>
        <label htmlFor="countdown_timer">Countdown Timer (seconds):</label>
        <input
          type="number"
          id="countdown_timer"
          name="countdown_timer"
          value={formData.countdown_timer}
          onChange={handleChange}
          placeholder="Countdown Timer"
          required
        />
      </div>
      <div>
        <label htmlFor="current_bid">Current Bid(pennce):</label>
        <input
          type="number"
          id="current_bid"
          name="current_bid"
          value={formData.current_bid}
          onChange={handleChange}
          placeholder="Current Bid"
          required
        />
      </div>
      <div>
        <label htmlFor="status">Status:</label>
        <select
          id="status"
          name="status"
          value={formData.status}
          onChange={handleChange}
        >
          <option value="upcoming">Upcoming</option>
          <option value="active">Active</option>
          <option value="completed">Completed</option>
        </select>
      </div>
      <button type="submit">{selectedAuction ? 'Update Auction' : 'Create Auction'}</button>
    </form>
  );
};

export default AuctionForm;