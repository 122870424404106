// WonAuctionsInvoiceHistory.tsx
import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Invoice, Auction, Product, Review, User, Address } from '../utils/types';
import { getAuctions, getProducts, getReviews, getUsers, getAddresses } from '../utils/coreAPI';
import { getUserInvoices, submitReview, createPaymentIntent } from '../utils/accountAPI';
import generateInvoice from '../utils/accountAPI';
import styles from './WonOrderForm.module.css'; // Import your CSS module

interface WonAuctionsInvoiceHistoryProps {
  token: string;
  userId: number;
}

interface EnrichedInvoice extends Invoice {
  auction?: Auction;
  product?: Product;
  review?: Review | null;
}

interface ReviewFormData {
  rating: number;
  comment: string;
  image: File | null;
}

const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY || '';
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);



const PaymentForm: React.FC<{ invoice: EnrichedInvoice; onPaymentSuccess: () => void }> = ({ invoice, onPaymentSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentError, setPaymentError] = useState('');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements) return;
    setIsProcessing(true);
    try {
      const { clientSecret } = await createPaymentIntent(Math.round(invoice.amount), 'GBP', invoice.id);
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: { card: elements.getElement(CardElement)! },
      });
      if (result.error) {
        setPaymentError(result.error.message || 'An error occurred during payment.');
      } else {
        onPaymentSuccess();
      }
    } catch (error) {
      setPaymentError('An error occurred while processing your payment.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.paymentForm}>
      <div className={styles.cardElementContainer}>
        <CardElement className={styles.cardElement} />
      </div>
      {paymentError && <div className={styles.paymentError}>{paymentError}</div>}
      <button type="submit" disabled={!stripe || isProcessing} className={styles.bidButton}>
        Pay £{invoice.amount}
      </button>
    </form>
  );
};

const WonAuctionsInvoiceHistory: React.FC<WonAuctionsInvoiceHistoryProps> = ({ token, userId }) => {
  const [invoices, setInvoices] = useState<EnrichedInvoice[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [reviewData, setReviewData] = useState<{ [key: number]: ReviewFormData }>({});
  const [user, setUser] = useState<User | null>(null);
  const [shippingAddress, setShippingAddress] = useState<Address | null>(null);
  const [billingAddress, setBillingAddress] = useState<Address | null>(null);
  const [dropdowns, setDropdowns] = useState({
    new: false,
    toBeShipped: false,
    shipped: false,
    waitingReview: false,
    completed: false,
  });

  useEffect(() => {
    fetchData();
  }, [userId, token]);

  const fetchData = async () => {
    setLoading(true);
    setError('');
    try {
      const [invoicesResponse, auctionsResponse, productsResponse, reviewsResponse, userResponse, addressResponse] = await Promise.all([
        getUserInvoices(token),
        getAuctions(),
        getProducts(),
        getReviews(),
        getUsers(),
        getAddresses()
      ]);

      const enrichedInvoices = invoicesResponse
        .filter((invoice: Invoice) => invoice.user_id === userId)
        .map((invoice: Invoice) => {
          const auction = auctionsResponse.data.find((a: Auction) => a.id === invoice.auction_id);
          const product = productsResponse.data.find((p: Product) => p.id === auction?.product_id);
          const review = reviewsResponse.data.find((r: Review) => r.auction_id === invoice.auction_id);
          return { ...invoice, auction, product, review };
        })
        .filter((invoice: EnrichedInvoice) => invoice.auction && invoice.product);

      setInvoices(enrichedInvoices);
      setUser(userResponse.data.find((u: User) => u.id === userId) || null);
      setShippingAddress(addressResponse.data.find((a: Address) => a.user_id === userId && a.address_type === 'SHIPPING') || null);
      setBillingAddress(addressResponse.data.find((a: Address) => a.user_id === userId && a.address_type === 'BILLING') || null);
    } catch (err) {
      setError('Failed to fetch won auctions');
    } finally {
      setLoading(false);
    }
  };

  const toggleDropdown = (section: keyof typeof dropdowns) => {
    setDropdowns(prev => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const handleReviewChange = (auctionId: number, field: keyof ReviewFormData, value: string | number | File | null) => {
    setReviewData(prevData => ({
      ...prevData,
      [auctionId]: {
        ...prevData[auctionId],
        [field]: value,
      },
    }));
  };

  const handleSubmitReview = async (auctionId: number) => {
    try {
      const review = reviewData[auctionId];
      if (!review) return;

      const formData = new FormData();
      formData.append('rating', review.rating.toString());
      formData.append('comment', review.comment);
      if (review.image) {
        formData.append('image', review.image);
      }

      await submitReview(token, auctionId, formData);
      alert('Review submitted successfully!');
      fetchData();
    } catch (err) {
      alert('Failed to submit review. Please try again.');
    }
  };

  const handleGenerateInvoice = async (invoice: EnrichedInvoice) => {
    if (!invoice.auction || !invoice.product || !user || !shippingAddress || !billingAddress) {
      alert('Missing billing or shipping address. Please complete your addresses before generating an invoice.');
      return;
    }
    try {
      await generateInvoice(invoice, user, invoice.product, invoice.auction, shippingAddress, billingAddress);
    } catch (error) {
      console.error('Error generating invoice:', error);
      alert('Failed to generate invoice. Please try again.');
    }
  };

  const handlePaymentSuccess = (invoiceId: number) => {
    setInvoices(prevInvoices =>
      prevInvoices.map(invoice =>
        invoice.id === invoiceId ? { ...invoice, status: 'paid' } : invoice
      )
    );
  };

  if (loading) return <div>Loading won auctions...</div>;
  if (error) return <div>{error}</div>;

  const renderInvoiceList = (invoices: EnrichedInvoice[], section: string) => (
    <ul className={section === 'completed' ? styles.completedAuctionsList : styles.wonAuctionsList}>
      {invoices.map(invoice => (
        <li key={invoice.id} className={section === 'completed' ? styles.completedAuctionsItem : styles.wonAuctionsItem}>
          {section === 'completed' ? (
            <>
              <div className={styles.completedItemLeft}>
                <img
                  src={invoice.product?.media_url || '/default-image.jpg'}
                  alt={invoice.product?.title}
                  className={styles.completedProductImage}
                />
                <div className={styles.completedDetails}>
                  <p className={styles.completedItemDate}>
                    Completed on: {new Date(invoice.date).toLocaleDateString()}
                  </p>
                  <p className={styles.completedItemPrice}>Price: £{invoice.amount}</p>
                  <button
                    onClick={() => handleGenerateInvoice(invoice)}
                    className={styles.completedGenerateInvoice}
                  >
                    Generate Invoice
                  </button>
                </div>
              </div>
              <div className={styles.completedItemRight}>
                {invoice.review ? (
                  <div className={styles.completedReview}>
                    <h3 className={styles.completedReviewTitle}>Your Review</h3>
                    {invoice.review.image_url && (
                      <img
                        src={`${process.env.REACT_APP_API_URL || 'http://localhost:4000'}${invoice.review.image_url}`}
                        alt="Review"
                        className={styles.completedReviewImage}
                      />
                    )}
                    <p className={styles.completedReviewRating}>
                      Rating: {invoice.review.rating} / 5
                    </p>
                    <p className={styles.completedReviewComment}>{invoice.review.review_text}</p>
                  </div>
                ) : (
                  <p>No review available.</p>
                )}
              </div>
            </>
          ) : (
            <>
              <div className={styles.wonItemHeader}>
                <img
                  src={invoice.product?.media_url || '/default-image.jpg'}
                  alt={invoice.product?.title}
                  className={styles.wonItemImage}
                />
                <div className={styles.wonItemDetails}>
                  <strong>{invoice.product?.title || 'Unknown Product'}</strong>
                  <span className={styles.wonItemDate}>
                    {section === 'new'
                      ? 'Invoice Date: '
                      : section === 'toBeShipped'
                        ? 'Paid on: '
                        : section === 'shipped'
                          ? 'Shipped on: '
                          : section === 'waitingReview'
                            ? 'Delivered on: '
                            : 'Completed on: '}
                    {new Date(invoice.date).toLocaleDateString()}
                  </span>
                  <div className={styles.wonItemPrice}>Total: £{invoice.amount}</div>

                  {section !== 'new' && (
                    <button
                      onClick={() => handleGenerateInvoice(invoice)}
                      className={styles.bidButton}
                    >
                      Generate Invoice
                    </button>
                  )}
                </div>
              </div>

              {section === 'new' && (
                <Elements stripe={stripePromise}>
                  <PaymentForm
                    invoice={invoice}
                    onPaymentSuccess={() => handlePaymentSuccess(invoice.id)}
                  />
                </Elements>
              )}

              {section === 'waitingReview' && (
                <div className={styles.wonReviewForm}>
                  <h4 className={styles.sectionTitle}>Leave a Review</h4>
                  <div className={styles.creditsForm}>
                    <select
                      value={reviewData[invoice.auction_id!]?.rating || ''}
                      onChange={(e) => handleReviewChange(invoice.auction_id!, 'rating', Number(e.target.value))}
                      className={styles.creditsSelect}
                    >
                      <option value="">Rating</option>
                      {[1, 2, 3, 4, 5].map(num => (
                        <option key={num} value={num}>{num} Star{num !== 1 ? 's' : ''}</option>
                      ))}
                    </select>
                    <textarea
                      value={reviewData[invoice.auction_id!]?.comment || ''}
                      onChange={(e) => handleReviewChange(invoice.auction_id!, 'comment', e.target.value)}
                      placeholder="Write your review here..."
                      className={styles.creditsSelect}
                    />
                    <input required
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleReviewChange(invoice.auction_id!, 'image', e.target.files?.[0] || null)}
                      className={styles.creditsSelect}
                    />
                    <button
                      onClick={() => handleSubmitReview(invoice.auction_id!)}
                      className={styles.bidButton}
                      disabled={!reviewData[invoice.auction_id!]?.rating || !reviewData[invoice.auction_id!]?.comment}
                    >
                      Submit Review
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </li>
      ))}
    </ul>
  );

  return (
    <div className={styles.creditsSection}>
      <h3 className={styles.sectionTitle}>Won Auctions</h3>

      {['new', 'toBeShipped', 'shipped', 'waitingReview', 'completed'].map((section) => (
        <div key={section} className={styles.historySection}>
          <button onClick={() => toggleDropdown(section as keyof typeof dropdowns)} className={styles.historyToggle}>
            {section === 'new' ? 'Newly Won Auctions' :
              section === 'toBeShipped' ? 'To Be Shipped' :
                section === 'shipped' ? 'Shipped' :
                  section === 'waitingReview' ? 'Waiting for Review' :
                    'Completed Auctions'} ({
              invoices.filter(invoice => {
                if (section === 'new') return invoice.status === 'awaiting payment';
                if (section === 'toBeShipped') return invoice.status === 'paid';
                if (section === 'shipped') return invoice.status === 'shipped';
                if (section === 'waitingReview') return invoice.status === 'delivered';
                if (section === 'completed') return invoice.status === 'completed';
                return false;
              }).length
            })
            <span className={styles.toggleIcon}>{dropdowns[section as keyof typeof dropdowns] ? '▲' : '▼'}</span>
          </button>
          {dropdowns[section as keyof typeof dropdowns] && (
            <div className={styles.invoiceHistory}>
              {renderInvoiceList(
                invoices.filter(invoice => {
                  if (section === 'new') return invoice.status === 'awaiting payment';
                  if (section === 'toBeShipped') return invoice.status === 'paid';
                  if (section === 'shipped') return invoice.status === 'shipped';
                  if (section === 'waitingReview') return invoice.status === 'delivered';
                  if (section === 'completed') return invoice.status === 'completed';
                  return false;
                }),
                section
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default WonAuctionsInvoiceHistory;
