import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAuctionById, getProductById, getReviews, getBids } from '../utils/coreAPI';
import { Auction, Product, Review, Bid } from '../utils/types';
import { Star, Clock, Users, DollarSign, Package, Truck } from 'lucide-react';
import styles from './details.module.css'; // Importing CSS Module

const AuctionPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [auction, setAuction] = useState<Auction | null>(null);
    const [product, setProduct] = useState<Product | null>(null);
    const [reviews, setReviews] = useState<Review[]>([]);
    const [bids, setBids] = useState<Bid[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const token = localStorage.getItem('token'); // or however you're storing the token

    useEffect(() => {
        const fetchAuctionData = async () => {
            setLoading(true);
            try {

                const auctionData = await getAuctionById(parseInt(id!, 10));
                setAuction(auctionData.data);

                const productData = await getProductById(auctionData.data.product_id);
                setProduct(productData.data);

                const allReviewsData = await getReviews();
                const allBidsData = await getBids();

                setBids(allBidsData.data.filter((bid: { auction_id: any; }) => bid.auction_id === auctionData.data.id));
                
                const productReviews = allReviewsData.data.filter((review: { auction_id: any; }) => 
                    review.auction_id === auctionData.data.id
                );

                setReviews(productReviews);
                setLoading(false);
            
            } catch (err) {
                setError('Failed to fetch auction details');
                setLoading(false);
            }
        };

        fetchAuctionData();
    }, [id]);

    const renderStars = (rating: number) => {
        return Array(5).fill(0).map((_, i) => (
            <Star key={i} fill={i < rating ? "#FFD700" : "none"} stroke="#FFD700" size={18} />
        ));
    };

    if (loading) return <div className={styles.auctionDetailsLoading}>Loading...</div>;
    if (error) return <div className={styles.auctionDetailsError}>{error}</div>;
    if (!auction || !product) return <div className={styles.auctionDetailsError}>Auction not found</div>;

    const timeLeft = new Date(auction.start_time).getTime() + (auction.countdown_timer * 1000) - Date.now();
    const formatTimeLeft = () => {
        const hours = Math.floor(timeLeft / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
        return `${hours}h ${minutes}m ${seconds}s`;
    };

    return (
        <div className={styles.auctionDetailsPage}>
            <div className={styles.auctionDetailsHeader}>
                <h1>{product.title}</h1>
                <div className={styles.auctionDetailsStatus}>{auction.status}</div>
            </div>
            
            <div className={styles.auctionDetailsContent}>
                <div className={styles.auctionDetailsMain}>
                    <div className={styles.auctionDetailsImageContainer}>
                        <img src={product.media_url || 'default-image-url'} alt={product.title} className={styles.auctionDetailsImage} />
                    </div>
                    
                    <div className={styles.auctionDetailsInfo}>
                        <div className={styles.auctionDetailsPrice}>
                            <DollarSign size={24} />
                            <span>Current Bid: £{(auction.current_bid/100).toFixed(2)}</span>
                        </div>
                        
                        <div className={styles.auctionDetailsTimeLeft}>
                            <Clock size={24} />
                            <span>Time Left: {formatTimeLeft()}</span>
                        </div>
                        
                        <div className={styles.auctionDetailsBidders}>
                            <Users size={24} />
                            <span>Bidders: {bids.length}</span>
                        </div>
                        
                        <button className={styles.auctionDetailsBidButton}>Place Bid</button>
                    </div>
                    
                    <div className={styles.auctionDetailsDescription}>
                        <h2>Product Description</h2>
                        <p>{product.description}</p>
                    </div>
                    
                    <div className={styles.auctionDetailsProductInfo}>
                        <h2>Product Details</h2>
                        <div className={styles.auctionDetailsProductItem}>
                            <Package size={18} />
                            <span>RRP: £{product.rrp}</span>
                        </div>
                        <div className={styles.auctionDetailsProductItem}>
                            <Truck size={18} />
                            <span>Postage: £{product.postage}</span>
                        </div>
                    </div>
                    
                    <div className={styles.auctionDetailsBidHistory}>
                        <h2>Bid History</h2>
                        <ul>
                            {bids.map((bid) => (
                                <li key={bid.id} className={styles.auctionDetailsBidItem}>
                                    <span>Bidder {bid.user_id}</span>
                                    <span>{new Date(bid.bid_time).toLocaleString()}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                
                <div className={styles.auctionDetailsSidebar}>
                    <div className={styles.auctionDetailsReviews}>
                        <h2>Product Reviews</h2>
                        {reviews.length > 0 ? (
                            reviews.map(review => (
                                <div key={review.id} className={styles.auctionDetailsReviewItem}>
                                    <div className={styles.auctionDetailsReviewRating}>
                                        {renderStars(review.rating)}
                                    </div>
                                    <p className={styles.auctionDetailsReviewText}>{review.review_text}</p>
                                    {review.image_url && (
                                        <img src={review.image_url} alt="Review" className={styles.auctionDetailsReviewImage} />
                                    )}
                                </div>
                            ))
                        ) : (
                            <p>No reviews yet for this product.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuctionPage;
