import React from 'react';
import { Auction, Product } from '../utils/types';
import { Clock, User, Eye } from 'lucide-react';
import styles from './AuctionGrid.module.css';

interface AuctionGridProps {
    auctions: Auction[];
    products: Product[];
    timers: Map<number, number>;
    prevBidders: Map<number, { username: string; uid: number }>;
    handleBid: (auctionId: number) => Promise<void>;
}

const AuctionGrid: React.FC<AuctionGridProps> = ({ auctions, products, timers, prevBidders, handleBid }) => {
    const formatTime = (seconds: number): string => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const formatSTime = (date: Date): string => {
        return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    };

    const formatDate = (date: Date): string => {
        return date.toLocaleDateString('en-UK', { month: 'short', day: 'numeric', year: 'numeric' });
    };

    const getCountdown = (startTime: string): string => {
        const now = new Date();
        const start = new Date(startTime);
        const diff = start.getTime() - now.getTime();
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        return `${days}d ${hours}h ${minutes}m`;
    };

    const productMap = new Map(products.map(product => [product.id, product]));

    return (
        <div className={styles.auctionGrid}>
            {auctions.map((auction) => {
                const product = productMap.get(auction.product_id);
                const remainingTime = timers.get(auction.id) || 0;
                const isActive = auction.status === 'active';
                const isCompleted = auction.status === 'completed';
                const isEndingSoon = remainingTime <= 900;
                const prevBidder = prevBidders.get(auction.id);
                const link = `auction/${auction.id}`;

                if (!product) return null;

                return (
                    <div key={auction.id} className={`${styles.auctionCard} ${isEndingSoon ? styles.endingSoon : ''}`}>
                        <a href={link}>
                            <img src={product.media_url || 'default-image-url'} alt={product.title || 'Product Image'} className={styles.auctionImage} />
                        </a>

                        <div className={`${styles.auctionStatus} ${
                            isActive ? styles.statusActive : 
                            isCompleted ? styles.statusCompleted : 
                            styles.statusUpcoming
                        }`}>
                            {isActive ? 'Active' : isCompleted ? 'Completed' : 'Upcoming'}
                        </div>
                        <div className={styles.auctionDetails}>
                            <h3>{product.title}</h3>
                            <p className={styles.auctionPrice}>£{(auction.current_bid/100).toFixed(2) || 0}</p>
                            <div className={styles.auctionTimer}>
                                <Clock size={24} />
                                {isActive ? (
                                    auction.current_bid > 0
                                        ? formatTime(remainingTime)
                                        : formatTime(auction.countdown_timer)
                                ) : (
                                    `BID IN ${getCountdown(auction.start_time)}`
                                )}
                            </div>
                            {isActive ? (
                                <button
                                    className={styles.bidButton}
                                    onClick={() => handleBid(auction.id)}
                                >
                                    Place Bid Now
                                </button>
                            ) : (
                                <button className={styles.bidButton} disabled>
                                    STARTS {formatDate(new Date(auction.start_time))} <br />
                                    {formatSTime(new Date(auction.start_time))}
                                </button>
                            )}
                            <div className={styles.auctionInfo}>
                                <span><User size={16} /> {auction.umin} bids</span>
                                {/* <span><Eye size={16} /> {Math.floor(Math.random() * 100)} watching</span> */}
                            </div>
                            {prevBidder && (
                                <div className={styles.previousBidder}>
                                    Previous Bidder: {prevBidder.username || `User ID ${prevBidder.uid}`}
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default AuctionGrid;