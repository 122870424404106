// src/pages/BidsPage.tsx
import React, { useState, useEffect } from 'react';
import BidList from '../components/BidList';
import { getBids } from '../../utils/adminAPI';
import { Bid } from '../../utils/types';

const BidsPage: React.FC = () => {
  const [bids, setBids] = useState<Bid[]>([]); // State to store bid data
  const token = localStorage.getItem('token'); // or however you're storing the token

  useEffect(() => {
    fetchBids(); // Fetch bids on component mount
  }, []);

  const fetchBids = async () => {
    try {
      if (token) {
        const response = await getBids(token); // Fetch bids from the backend
        setBids(response.data);
      } // Set bids in state
    } catch (error) {
      console.error('Failed to fetch bids', error);
    }
  };

  return (
    <div>
      <h1>Manage Bids</h1>
      <BidList bids={bids} fetchBids={fetchBids} /> {/* Display bid list */}
    </div>
  );
};

export default BidsPage;
