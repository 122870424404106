// src/pages/IndexPage.tsx

import React, { useEffect, useState } from 'react';
import {  getProducts, getBids } from '../utils/coreAPI';
import { Auction, Product, Bid } from '../utils/types';
import { createBid, syncAuctionTimes, updateAuctionStatus } from '../utils/coreAPI';
import '../styles/mobile.css';
import io from 'socket.io-client';
import AuctionGrid from '../auction/AuctionGrid';
import PromoAuction from '../auction/PromoAuction';
import { useError } from '../components/ErrorContext';
import {getAuctions} from '../utils/coreAPI'

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
const socketUrl = apiUrl.endsWith('/api') ? apiUrl.slice(0, -4) : apiUrl; // Remove '/api' if it exists
const socket = io(socketUrl);

const IndexPage: React.FC = () => {
    const { showError } = useError();

    const [auctions, setAuctions] = useState<Auction[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [bids, setBids] = useState<Bid[]>([]);
    const [timers, setTimers] = useState<Map<number, number>>(new Map());
    const [featuredType, setFeaturedType] = useState<'hottest'|'lowest' | 'highest' | 'nextLive' | 'endingSoon'>(() => {
        const saved = localStorage.getItem('featuredType');
        return (saved as 'hottest' | 'lowest' |  'highest' | 'nextLive' | 'endingSoon') || 'lowest';
    });
    const [prevBidders, setPrevBidders] = useState<Map<number, { username: string; uid: number }>>(new Map());
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [auctionsResponse, productsResponse, bidsResponse, syncTimesResponse] = await Promise.all([
                    getAuctions(),
                    getProducts(),
                    getBids(),
                    syncAuctionTimes(),
                ]);

                const filteredAuctions = auctionsResponse.data.filter(
                    (auction: Auction) => auction.status === 'upcoming' || auction.status === 'active'
                );

                setAuctions(filteredAuctions);
                setProducts(productsResponse.data);
                setBids(bidsResponse.data);

                const initialTimers = new Map<number, number>();
                filteredAuctions.forEach((auction: Auction) => {
                    initialTimers.set(auction.id, syncTimesResponse[auction.id].data.remainingTime || auction.countdown_timer);
                });
                setTimers(initialTimers);
            } catch (error) {
                console.log('Failed to fetch data', error);
            }
        };

        fetchData();

        socket.on('auctionUpdate', handleAuctionUpdate);
        socket.on('bidPlaced', handleBidPlaced);
        socket.on('auctionSync', handleAuctionSync);

        return () => {
            socket.off('auctionUpdate', handleAuctionUpdate);
            socket.off('bidPlaced', handleBidPlaced);
            socket.off('auctionSync', handleAuctionSync);
        };
    }, []);

    useEffect(() => {
        localStorage.setItem('featuredType', featuredType);
        socket.emit('updateFeaturedType', featuredType);
    }, [featuredType]);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimers((prevTimers) => {
                const newTimers = new Map<number, number>();
                prevTimers.forEach((time, auctionId) => {
                    
                    if (time > 0) {
                        newTimers.set(auctionId, time - 1);
                    }
                });
                return newTimers;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const currentTime = new Date().getTime();

        auctions.forEach(async (auction) => {
            const auctionStartTime = new Date(auction.start_time).getTime();
            const elapsed = Math.floor((currentTime - auctionStartTime) / 1000);

            if (elapsed >= 0 && auction.status === 'upcoming') {
                try {
                    await updateAuctionStatus(auction.id, { ...auction, status: 'active' });
                    setAuctions((prevAuctions) =>
                        prevAuctions.map((a) => (a.id === auction.id ? { ...a, status: 'active' } : a))
                    );
                } catch (error) {
                    console.error('Failed to update auction status', error);
                    showError('Failed to update auction status. Please refresh the page.', 'error');
                }
            }
        });
    }, [auctions]);

    const handleAuctionUpdate = (updatedAuction: Auction) => {
        setAuctions((prevAuctions) =>
            prevAuctions.map((auction) => (auction.id === updatedAuction.id ? updatedAuction : auction))
        );
    };

    const handleBidPlaced = (data: { auction: Auction; bid: Bid; uid: number; username: string }) => {
        const { auction, bid, uid, username } = data;
        console.log(data);

        setPrevBidders((prevMap) => {
            const newMap = new Map(prevMap);
            newMap.set(auction.id, { username, uid });
            return newMap;
        });

        setAuctions((prevAuctions) =>
            prevAuctions.map((a) => (a.id === auction.id ? { ...a, current_bid: auction.current_bid + 1 } : a))
        );

        setBids((prevBids) => [...prevBids, bid]);

        setTimers((prevTimers) => {
            const newTimers = new Map(prevTimers);
            newTimers.set(auction.id, auction.countdown_timer);
            return newTimers;
        });
    };

    const handleAuctionSync = (syncData: { auction_id: number; remainingTime: number }[]) => {
        setTimers((prevTimers) => {
            const newTimers = new Map(prevTimers);
            syncData.forEach(({ auction_id, remainingTime }) => {
                newTimers.set(auction_id, remainingTime);
            });
            return newTimers;
        });
    };

    const handleBid = async (auctionId: number) => {
        try {
            const auction = auctions.find((auction) => auction.id === auctionId);
            if (!auction) {
                showError('Auction not found. Please refresh the page.', 'error');
                return;
            }

            if (auction.status !== 'active') {
                showError('This auction is not active. You cannot place a bid at this time.', 'warning');
                return;
            }

            if (!token) {
                showError('You must be logged in to place a bid.', 'info');
                return;
            }

            await createBid(token, {
                auction_id: auctionId,
                bid_time: new Date().toISOString(),
            });
        } catch (error) {
            console.error('Failed to place bid', error);
            showError('Failed to place bid. Please try again.', 'error');
        }
    };

    return (
        <div className="container">
            <PromoAuction
                auctions={auctions}
                products={products}
                bids={bids}
                timers={timers}
                featuredType={featuredType}
                setFeaturedType={setFeaturedType}
                prevBidders={prevBidders}
                handleBid={handleBid}
            />

            <h2 className="section-title">Active Auctions</h2>
            <AuctionGrid
                auctions={auctions}
                products={products}
                timers={timers}
                prevBidders={prevBidders}
                handleBid={handleBid}
            />
        </div>
    );
};

export default IndexPage;