// src/components/ProductForm.tsx
import React, { useState, useEffect } from 'react';
import { Product } from '../../utils/types';
import { createProduct, updateProduct } from '../../utils/adminAPI';

interface ProductFormProps {
  selectedProduct: Product | null;
  onSubmit: () => void;
}
const token = localStorage.getItem('token'); // or however you're storing the token

const ProductForm: React.FC<ProductFormProps> = ({ selectedProduct, onSubmit }) => {
  const [formData, setFormData] = useState<Omit<Product, 'id'>>({
    title: '',
    description: '',
    rrp: 0,
    postage: 0,
    stock: 0,
    media_url: '',
  });

  useEffect(() => {
    if (selectedProduct) {
      // When editing, use all fields from the selected product
      const { id, ...productWithoutId } = selectedProduct;
      setFormData(productWithoutId);
    } else {
      // When creating, reset the form
      setFormData({
        title: '',
        description: '',
        rrp: 0,
        postage: 0,
        stock: 0,
        media_url: '',
      });
    }
  }, [selectedProduct]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: name === 'rrp' || name === 'postage' || name === 'stock' ? Number(value) : value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if(token){
      if (selectedProduct) {
        await updateProduct(token,selectedProduct.id, formData);
      } else {
        await createProduct(token,formData);
      }
      onSubmit();}
    } catch (error) {
      console.error('Error saving product', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="title">Title:</label>
      <input 
        type="text" 
        name="title" 
        value={formData.title} 
        onChange={handleChange} 
        placeholder="Title" 
        required 
      />
      
      <label htmlFor="description">Description:</label>
      <textarea 
        name="description" 
        value={formData.description} 
        onChange={handleChange} 
        placeholder="Description" 
        required 
      />
      
      <label htmlFor="rrp">RRP(pounds):</label>
      <input 
        type="number" 
        name="rrp" 
        value={formData.rrp} 
        onChange={handleChange} 
        placeholder="Price (RRP)" 
        step="0.01"
        required 
      />
      
      <label htmlFor="postage">P&P(pennies):</label>
      <input 
        type="number" 
        name="postage" 
        value={formData.postage} 
        onChange={handleChange} 
        placeholder="Postage Cost" 
        step="0.01"
        required 
      />
      
      <label htmlFor="stock">Stock:</label>
      <input 
        type="number" 
        name="stock" 
        value={formData.stock} 
        onChange={handleChange} 
        placeholder="Stock" 
        required 
      />
      
      <label htmlFor="media_url">Photo Link:</label>
      <input 
        type="text" 
        name="media_url" 
        value={formData.media_url} 
        onChange={handleChange} 
        placeholder="Image URL" 
      />
      
      <button type="submit">
        {selectedProduct ? 'Update Product' : 'Create Product'}
      </button>
    </form>
  );
};

export default ProductForm;