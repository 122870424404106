import React from 'react';
import { Link } from 'react-router-dom';
import { Mail, Phone, MapPin } from 'lucide-react';
import styles from './footer.module.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <div className={styles.footer__content}>
        <div className={`${styles.footer__section} ${styles.footer__sectionMain}`}>
          <img
            src="https://ushop.bid/uploads/logo.png"
            alt="UShop Logo"
            className={styles.footer__logo}
          />
          <p className={styles.footer__description}>
            UShop: Your premier destination for exciting online auctions. Discover great deals and
            enjoy thrilling bidding experiences.
          </p>

          <div className={styles.footer__qrCodes}>
            <a
              href="https://www.facebook.com/profile.php?id=100083428923085"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.footer__qrCode}
            >
              <h5>Facebook</h5>
              <img
                src={`${process.env.REACT_APP_API_URL?.replace('/api', '') || 'http://127.0.0.1:4000'
                  }/uploads/fbqr.png`}
                alt="Facebook QR Code"
              />
            </a>
            <a
              href="https://www.tiktok.com/@ushop.bid?_t=8q5ato3Ljl2&_r=1"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.footer__qrCode}
            >
              <h5>TikTok</h5>
              <img
                src={`${process.env.REACT_APP_API_URL?.replace('/api', '') || 'http://127.0.0.1:4000'
                  }/uploads/tiktokqr.png`}
                alt="TikTok QR Code"
              />
            </a>
            <a
              href="https://www.instagram.com/ushop.bid?igsh=cDd3aWF4NWd5OGJs"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.footer__qrCode}
            >
              <h5>Instagram</h5>
              <img
                src={`${process.env.REACT_APP_API_URL?.replace('/api', '') || 'http://127.0.0.1:4000'
                  }/uploads/instaqr.png`}
                alt="Instagram QR Code"
              />
            </a>
          </div>
        </div>

        <div className={styles.footer__section}>
          <h3 className={styles.footer__title}>Quick Links</h3>
          <ul className={styles.footer__list}>
            <li>
              <Link to="/" className={styles.footer__link}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/upcoming" className={styles.footer__link}>
                Upcoming Auctions
              </Link>
            </li>
            <li>
              <Link to="/winners" className={styles.footer__link}>
                Winners
              </Link>
            </li>
            <li>
              <Link to="/faq" className={styles.footer__link}>
                FAQ
              </Link>
            </li>
            <li>
              <Link to="/contact" className={styles.footer__link}>
                Contact Us
              </Link>
            </li>
          </ul>
        </div>

        <div className={styles.footer__section}>
          <h3 className={styles.footer__title}>Legal</h3>
          <ul className={styles.footer__list}>
            <li>
              <Link to="/info" className={styles.footer__link}>
                Terms of Service
              </Link>
            </li>
            <li>
              <Link to="/info" className={styles.footer__link}>
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link to="/info" className={styles.footer__link}>
                Refund Policy
              </Link>
            </li>
            <li>
              <Link to="/info" className={styles.footer__link}>
                Shipping Information
              </Link>
            </li>
          </ul>
        </div>

        <div className={styles.footer__section}>
          <h3 className={styles.footer__title}>Contact Us</h3>
          <ul className={styles.footer__list}>
            <li className={styles.footer__contactItem}>
              <Mail size={16} />
              <a href="mailto:support@ushop.com" className={styles.footer__link}>
                info@ushop.com
              </a>
            </li>
            <li className={styles.footer__contactItem}>
              <Phone size={16} />
              <a href="tel:+18001234567" className={styles.footer__link}>
                +447588 540949
              </a>
            </li>
            <li className={styles.footer__contactItem}>
              <MapPin size={16} />
              <span>27 Old Gloucester St, London, WC19 XCN</span>
            </li>
          </ul>
        </div>
      </div>

      <div className={styles.footer__bottom}>
        <p>&copy; {currentYear} UShop. All rights reserved.</p>
        <p>Developed by A.I Trading House</p>
      </div>
    </footer>
  );
};

export default Footer;
