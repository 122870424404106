import React, { useState, useEffect } from 'react';
import { Clock, User, Eye } from 'lucide-react';

// Types
interface Auction {
  id: number;
  product_id: number;
  status: 'upcoming' | 'active' | 'completed';
  current_bid: number;
  countdown_timer: number;
  start_time: string;
  umin: number;
}

interface Product {
  id: number;
  title: string;
  media_url: string;
}

// Styles
const styles = `
  .dynamicAuctionGrid {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-bottom: 40px;
  }
  
  .auctionCard {
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .auctionCard:hover {
    transform: translateY(-5px);
  }
  
  .auctionImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .auctionDetails {
    padding: 15px;
  }
  
  .auctionPrice {
    font-size: 1.2em;
    font-weight: bold;
    color: #4a4a4a;
  }
  
  .auctionInfo {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 0.9em;
    color: #777;
  }
  
  .bidButton {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .bidButton:hover:not(:disabled) {
    background-color: #0056b3;
  }
  
  .bidButton:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .topOverlay, .bottomOverlay {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 0.9em;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .topOverlay {
    top: 10px;
  }
  
  .bottomOverlay {
    bottom: -15px;
  }
  
  .timer, .prevBidder {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .endingSoon .bottomOverlay {
    background-color: rgba(255, 0, 0, 0.7);
  }
  
  @media (max-width: 768px) {
    .grid {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  }
`;

// Component
const DynamicAuctionGrid: React.FC = () => {
  const [auctions, setAuctions] = useState<Auction[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [timers, setTimers] = useState<Map<number, number>>(new Map());
  const [prevBidders, setPrevBidders] = useState<Map<number, { username: string; uid: number }>>(new Map());

  useEffect(() => {
    // Simulating API calls
    const fetchData = async () => {
      // Mock data
      const mockAuctions: Auction[] = [
        { id: 1, product_id: 1, status: 'active', current_bid: 10000, countdown_timer: 3600, start_time: '2023-05-20T10:00:00', umin: 5 },
        { id: 2, product_id: 2, status: 'upcoming', current_bid: 5000, countdown_timer: 7200, start_time: '2023-05-21T14:00:00', umin: 0 },
        // Add more mock auctions as needed
      ];

      const mockProducts: Product[] = [
        { id: 1, title: 'Smartphone X', media_url: 'https://via.placeholder.com/300' },
        { id: 2, title: 'Laptop Y', media_url: 'https://via.placeholder.com/300' },
        // Add more mock products as needed
      ];

      setAuctions(mockAuctions);
      setProducts(mockProducts);

      const initialTimers = new Map<number, number>();
      const initialPrevBidders = new Map<number, { username: string; uid: number }>();
      mockAuctions.forEach(auction => {
        initialTimers.set(auction.id, auction.countdown_timer);
        initialPrevBidders.set(auction.id, { username: 'John Doe', uid: 123 });
      });
      setTimers(initialTimers);
      setPrevBidders(initialPrevBidders);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimers(prevTimers => {
        const newTimers = new Map(prevTimers);
        newTimers.forEach((value, key) => {
          if (value > 0) {
            newTimers.set(key, value - 1);
          }
        });
        return newTimers;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleBid = async (auctionId: number) => {
    console.log(`Placed bid on auction ${auctionId}`);
    // Implement actual bid logic here
  };

  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const getCountdown = (startTime: string): string => {
    const now = new Date();
    const start = new Date(startTime);
    const diff = start.getTime() - now.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    return `${days}d ${hours}h ${minutes}m`;
  };

  const renderAuctionCard = (auction: Auction) => {
    const product = products.find(p => p.id === auction.product_id);
    const remainingTime = timers.get(auction.id) || 0;
    const isActive = auction.status === 'active';
    const isEndingSoon = remainingTime <= 900;
    const prevBidder = prevBidders.get(auction.id);

    if (!product) return null;

    return (
      <div key={auction.id} className={`auctionCard ${isEndingSoon ? 'endingSoon' : ''}`}>
        <div className="topOverlay">
          {prevBidder && (
            <div className="prevBidder">
              <User size={16} /> {prevBidder.username || `User ${prevBidder.uid}`}
            </div>
          )}
        </div>
        <img src={product.media_url} alt={product.title} className="auctionImage" />
        <div className="auctionDetails">
          <h3>{product.title}</h3>
          <p className="auctionPrice">£{(auction.current_bid/100).toFixed(2)}</p>
          <div className="auctionInfo">
            <span><User size={16} /> {auction.umin} bids</span>
            <span><Eye size={16} /> {Math.floor(Math.random() * 100)} watching</span>
          </div>
          <button
            className="bidButton"
            onClick={() => handleBid(auction.id)}
            disabled={!isActive}
          >
            {isActive ? 'Place Bid Now' : 'Upcoming'}
          </button>
        </div>
        <div className="bottomOverlay">
          <div className="timer">
            <Clock size={16} />
            {isActive ? formatTime(remainingTime) : `Starts in ${getCountdown(auction.start_time)}`}
          </div>
        </div>
      </div>
    );
  };

  const activeAuctions = auctions.filter(auction => auction.status === 'active');
  const upcomingAuctions = auctions.filter(auction => auction.status === 'upcoming');

  return (
    <div className="dynamicAuctionGrid">
      <style>{styles}</style>
      <h2>Live Auctions</h2>
      <div className="grid">
        {activeAuctions.map(renderAuctionCard)}
      </div>
      <h2>Upcoming Auctions</h2>
      <div className="grid">
        {upcomingAuctions.map(renderAuctionCard)}
      </div>
    </div>
  );
};

// Page Component
const TestPage: React.FC = () => {
  return (
    <div>
      <h1>Auction Test Page</h1>
      <DynamicAuctionGrid />
    </div>
  );
};

export default TestPage;