import React, { useEffect, useState } from 'react';
import { getUserBiddingHistory } from '../utils/accountAPI';
import { Bid, Auction, Product } from '../utils/types';
import { getAuctionById, getProductById, getBids } from '../utils/coreAPI';

interface BiddingHistoryProps {
  token: string;
}

interface EnrichedBid extends Bid {
  auction: Auction;
  product: Product;
  bidCount: number;
}

const BIDDING_HISTORY_HEADERS = ['Bid ID', 'Product', 'Num Bids', 'Status', 'Date', 'Auction ID', 'Product ID'];

const BiddingHistory: React.FC<BiddingHistoryProps> = ({ token }) => {
  const [bids, setBids] = useState<EnrichedBid[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    fetchBiddingHistory();
  }, [token]);

  const countPreviousBids = (allBids: Bid[], currentBid: Bid): number => {
    return allBids.filter(bid => 
      bid.auction_id === currentBid.auction_id && bid.bid_time <= currentBid.bid_time
    ).length;
  };

  const fetchBiddingHistory = async () => {
    setLoading(true);
    setError('');
    try {
      const [userBids, allBids] = await Promise.all([
        getUserBiddingHistory(token),
        getBids()
      ]);

      const enrichedBids = await Promise.all(userBids.map(async (bid) => {
        const auction = await getAuctionById(bid.auction_id);
        const product = await getProductById(auction.data.product_id);
        const bidCount = countPreviousBids(allBids.data, bid);
        return {
          ...bid,
          auction: auction.data,
          product: product.data,
          bidCount,
        };
      }));
      setBids(enrichedBids);
    } catch (err: any) {
      setError('Failed to fetch bidding history.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Bidding History</h2>
      <div style={styles.tableContainer}>
        {loading ? (
          <p>Loading bidding history...</p>
        ) : error ? (
          <p style={styles.error}>{error}</p>
        ) : bids.length === 0 ? (
          <p>No bidding history available.</p>
        ) : (
          <table style={styles.table}>
            <thead style={styles.tableHeader}>
              <tr>
                {BIDDING_HISTORY_HEADERS.map((header) => (
                  <th key={header} style={styles.th}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {bids.map((bid) => (
                <tr key={bid.id} style={styles.tr}>
                  <td style={styles.td}>{bid.id}</td>
                  <td style={styles.td}>
                    <div style={styles.productCell}>
                      <img
                        src={bid.product.media_url || '/api/placeholder/50/50'}
                        alt={bid.product.title}
                        style={styles.productImage}
                      />
                      <span>{bid.product.title}</span>
                    </div>
                  </td>
                  <td style={styles.td}>{bid.bidCount}</td>
                  <td style={styles.td}>{bid.auction.status}</td>
                  <td style={styles.td}>{new Date(bid.bid_time).toLocaleString()}</td>
                  <td style={styles.td}>{bid.auction_id}</td>
                  <td style={styles.td}>{bid.product.id}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    marginTop: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  title: {
    fontSize: '24px',
    marginBottom: '15px',
  },
  tableContainer: {
    maxHeight: '400px',
    overflowY: 'auto',
    border: '1px solid #ddd',
    borderRadius: '5px',
    padding: '10px',
    backgroundColor: '#fff',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHeader: {
    backgroundColor: '#f2f2f2',
    position: 'sticky' as 'sticky',
    top: 0,
    zIndex: 1,
  },
  th: {
    padding: '12px',
    border: '1px solid #ddd',
    textAlign: 'left',
    backgroundColor: '#f9f9f9',
    fontWeight: 'bold',
  },
  td: {
    padding: '12px',
    border: '1px solid #ddd',
    textAlign: 'left',
    verticalAlign: 'middle',
  },
  productCell: {
    display: 'flex',
    alignItems: 'center',
  },
  productImage: {
    width: '50px',
    height: '50px',
    objectFit: 'cover' as 'cover',
    marginRight: '10px',
    borderRadius: '5px',
  },
  error: {
    color: 'red',
  },
};

export default BiddingHistory;