
// AdminInvoiceWinnerPage.tsx
import React, { useState, useEffect } from 'react';
import { Invoice, } from '../../utils/types';
import { getInvoices, } from '../../utils/adminAPI';
import InvoiceForm from '../components/InvoiceForm';
import InvoiceList from '../components/InvoiceList';
// import WinnerForm from './WinnerForm';
// import WinnerList from './WinnerList';

const AdminInvoiceWinnerPage: React.FC = () => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const token = localStorage.getItem('token'); // or however you're storing the token

  const fetchInvoices = async () => {
    if(token){
    const response = await getInvoices(token);
    setInvoices(response.data);}
  };



  useEffect(() => {
    fetchInvoices();
  }, []);

  const handleInvoiceSubmit = () => {
    setSelectedInvoice(null);
    fetchInvoices();
  };

 

  return (
    <div>
      <h1>Admin Invoice and Winner Management</h1>

      <section>
        <h2>Invoices</h2>
        <InvoiceForm selectedInvoice={selectedInvoice} onSubmit={handleInvoiceSubmit} />
        <InvoiceList
          invoices={invoices}
          onEdit={setSelectedInvoice}
          fetchInvoices={fetchInvoices}
        />
      </section>

      {/* <section>
        <h2>Winners</h2>
        <WinnerForm selectedWinner={selectedWinner} onSubmit={handleWinnerSubmit} />
        <WinnerList
          winners={winners}
          onEdit={setSelectedWinner}
          fetchWinners={fetchWinners}
        />
      </section> */}
    </div>
  );
};

export default AdminInvoiceWinnerPage;