// src/pages/HomePage.tsx
import React from 'react';
import { Link } from 'react-router-dom';

const HomePage: React.FC = () => {
  const links = [
    { path: '/admin/users', label: 'Manage Users' },
    { path: '/admin/products', label: 'Manage Products' },
    { path: '/admin/auctions', label: 'Manage Auctions' },
    { path: '/admin/bids', label: 'Manage Bids' },
    { path: '/admin/invoices', label: 'Manage Invoices' },
    { path: '/admin/reviews', label: 'Manage Reviews' },
    { path: '/admin/notifications', label: 'Manage Notifications' },
    { path: '/gov', label: 'Governor' },

  ];

  // Sort links alphabetically by label
  const sortedLinks = links.sort((a, b) => a.label.localeCompare(b.label));

  return (

    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px',  padding: '20px' }}>
    <h1>Welcome to the Admin Panel</h1>
    <p>Manage your auctions, users, products, and more from this dashboard.</p>

      {sortedLinks.map((link) => (
        <Link key={link.path} to={link.path}>
          <button style={styles.button}>{link.label}</button>
        </Link>
      ))}
    </div>
  );
};
const styles = {
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
  },
};

export default HomePage;
