import React, { useMemo } from 'react';
import { Auction, Product, Bid } from '../utils/types';
import { DollarSign, Clock, Users, Zap, Eye, TrendingUp } from 'lucide-react';
import styles from './PromoAuction.module.css';

interface PromoAuctionProps {
    auctions: Auction[];
    products: Product[];
    bids: Bid[];
    timers: Map<number, number>;
    featuredType: 'hottest' | 'lowest' | 'highest' | 'nextLive' | 'endingSoon';
    setFeaturedType: React.Dispatch<React.SetStateAction<'hottest' | 'lowest' | 'highest' | 'nextLive' | 'endingSoon'>>;
    prevBidders: Map<number, { username: string; uid: number }>;
    handleBid: (auctionId: number) => Promise<void>;
}

const PromoAuction: React.FC<PromoAuctionProps> = ({
    auctions,
    products,
    bids,
    timers,
    featuredType,
    setFeaturedType,
    prevBidders,
    handleBid
}) => {
    const getFeaturedAuction = () => {
        if (auctions.length === 0) return null;
        switch (featuredType) {
            case 'hottest':
                return auctions
                    .filter((auction) => auction.status === 'active')
                    .map(auction => {
                        const auctionBids = bids.filter(bid => bid.auction_id === auction.id);
                        const vBids = auctionBids.filter(bid => bid.type === 'V').length;
                        const uBids = auctionBids.filter(bid => bid.type === 'U').length;
                        return { ...auction, vBids, uBids };
                    })
                    .sort((a, b) => {
                        if (a.vBids !== b.vBids) {
                            return a.vBids - b.vBids; // Least 'v' bids
                        }
                        return b.uBids - a.uBids; // Most 'u' bids
                    })[0];
            case 'lowest':
                let ltemp = auctions.filter((auction) => auction.status === 'active')
                if (ltemp.length !== 0) {
                    return ltemp.reduce((prev, current) => (prev.current_bid < current.current_bid ? prev : current));
                }
                return null;
            case 'highest':
                let temp = auctions.filter((auction) => auction.status === 'active')
                if (temp.length !== 0) {
                    return temp.reduce((prev, current) => (prev.current_bid > current.current_bid ? prev : current));
                }
                return null;
            case 'nextLive':
                return auctions
                    .filter((auction) => auction.status === 'upcoming')
                    .sort((a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime())[0];
            case 'endingSoon':
                return auctions
                    .filter((auction) => auction.status === 'active')
                    .sort((a, b) => (timers.get(a.id) || Infinity) - (timers.get(b.id) || Infinity))[0];
            default:
                return null;
        }
    };

    const formatTime = (seconds: number): string => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };


    const getCountdown = (startTime: string): string => {
        const now = new Date();
        const start = new Date(startTime);
        const diff = start.getTime() - now.getTime();
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        return `${days}d ${hours}h ${minutes}m`;
    };

    const featuredAuction = getFeaturedAuction();
    const featuredProduct = featuredAuction ? products.find((p) => p.id === featuredAuction.product_id) : null;
    const remainingTime = featuredAuction ? timers.get(featuredAuction.id) || 0 : 0;
    const isEndingSoon = remainingTime <= 900;


    const uniqueBiddersCount = useMemo(() => {
        if (!featuredAuction) return 0;
        return new Set(bids.filter((bid) => bid.auction_id === featuredAuction.id).map((bid) => bid.user_id)).size;
    }, [bids, featuredAuction]);

    const prevBidder = featuredAuction ? prevBidders.get(featuredAuction.id) : undefined;

    return (
        <div className={styles.promoAuctionContainer}>
            <div className={styles.filterButtons}>
                {[
                    { type: 'hottest', icon: <TrendingUp size={16} />, label: 'Hottest' },
                    { type: 'lowest', icon: <DollarSign size={16} />, label: 'Lowest Price' },
                    { type: 'highest', icon: <DollarSign size={16} />, label: 'Highest Price' },
                    { type: 'nextLive', icon: <Zap size={16} />, label: 'Next Live' },
                    { type: 'endingSoon', icon: <Clock size={16} />, label: 'Ending Soon' },
                ].map(({ type, icon, label }) => (
                    <button
                        key={type}
                        onClick={() => setFeaturedType(type as any)}
                        className={`${styles.filterButton} ${featuredType === type ? styles.active : ''}`}
                    >
                        {icon}
                        {label}
                    </button>
                ))}
            </div>
            {featuredAuction && featuredProduct ? (
                <div className={`${styles.featuredAuction} ${isEndingSoon ? styles.endingSoon : ''}`}>
                    <div className={styles.auctionImageContainer}>
                        <img
                            src={featuredProduct.media_url || '/default-image.jpg'}
                            alt={featuredProduct.title}
                            className={styles.auctionImage}
                        />
                        <div
                            className={`${styles.auctionStatus} ${featuredAuction.status === 'active' ? styles.statusActive : styles.statusUpcoming
                                }`}
                        >
                            {featuredAuction.status === 'active' ? 'Active' : 'Upcoming'}
                        </div>
                    </div>
                    <div className={styles.auctionDetails}>
                        <h3 className={styles.auctionTitle}>{featuredProduct.title}</h3>
                        <p className={styles.productDescription}>{featuredProduct.description}</p>
                        <p className={styles.auctionPrice}>£{(featuredAuction.current_bid / 100).toFixed(2)}</p>
                        <div className={styles.auctionInfo}>
                            <span className={styles.auctionInfoItem}>
                                <Users size={16} /> {uniqueBiddersCount} bidders
                            </span>
                            <span className={styles.auctionInfoItem}>
                                {/* <Eye size={16} /> {Math.floor(Math.random() * 100)} watching */}
                            </span>
                            <span className={styles.auctionInfoItem}>RRP: ${featuredProduct.rrp}</span>
                            <span className={styles.auctionInfoItem}>
                                Start: {new Date(featuredAuction.start_time).toLocaleString()}
                            </span>
                        </div>
                        {prevBidder && (
                            <div className={styles.previousBidder}>
                                Previous Bidder: {prevBidder.username || `User ID ${prevBidder.uid}`}
                            </div>
                        )}
                        <div className={styles.auctionTimer}>
                            <Clock size={24} />
                            {featuredAuction.status === 'active' ? (
                                featuredAuction.current_bid > 0
                                    ? formatTime(remainingTime)
                                    : formatTime(featuredAuction.countdown_timer)
                            ) : (
                                // `Starts in ${(countdown)}`
                                `BID IN ${getCountdown(featuredAuction.start_time)}`

                            )}
                        </div>
                        {featuredAuction.status === 'active' ? (
                            <button className={styles.bidButton} onClick={() => handleBid(featuredAuction.id)}>
                                Place Your Bid Now!
                            </button>
                        ) : (
                            <p className={styles.upcomingMessage}>Auction starts soon! Get ready to bid.</p>
                        )}
                    </div>
                </div>
            ) : (
                <div className={styles.noAuctionsMessage}>
                    <h2>No Auctions Available</h2>
                    <p>Please check back later for upcoming auctions.</p>
                </div>
            )}
        </div>
    );
};

export default PromoAuction;