import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Auction, Product } from '../utils/types';
import { getProducts } from '../utils/coreAPI';
import { getAuctions } from '../utils/coreAPI';
import { Calendar, Grid, List, Clock, DollarSign, Eye } from 'lucide-react';
import styles from './upcoming.module.css';

type ViewType = 'grid' | 'calendar' | 'list';

const UpcomingAuctions: React.FC = () => {
  const [auctions, setAuctions] = useState<Auction[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [view, setView] = useState<ViewType>('grid');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
        const [auctionsResponse, productsResponse] = await Promise.all([
          getAuctions(),
          getProducts()
        ]);
        
        const sortedAndFilteredAuctions = auctionsResponse.data
          .filter(auction => new Date(auction.start_time) > new Date())
          .sort((a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime());

        setAuctions(sortedAndFilteredAuctions);
        setProducts(productsResponse.data);
        setLoading(false);
          } catch (err) {
      setError('Failed to fetch upcoming auctions');
      setLoading(false);
    }
  };

  const productMap = new Map(products.map(product => [product.id, product]));

  const formatDate = (date: Date): string => {
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  };

  const formatTime = (date: Date): string => {
    return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
  };

  const getCountdown = (startTime: string): string => {
    const now = new Date();
    const start = new Date(startTime);
    const diff = start.getTime() - now.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    return `${days}d ${hours}h ${minutes}m`;
  };

  const AuctionCard = ({ auction }: { auction: Auction }) => {
    const product = productMap.get(auction.product_id);
    if (!product) return null;
    const startTime = new Date(auction.start_time);

    return (
      <Link to={`/auction/${auction.id}`} className={styles.card}>
        <div className={styles.imageContainer}>
          <img src={product.media_url || 'default-image-url'} alt={product.title} className={styles.image} />
          <span className={`${styles.status} ${styles.statusUpcoming}`}>Upcoming</span>
        </div>
        <div className={styles.cardDetails}>
          <h3 className={styles.cardTitle}>{product.title}</h3>
          <p className={styles.cardInfo}><DollarSign size={16} /> Starting Bid: ${(auction.current_bid/100).toFixed(2)}</p>
          <p className={styles.cardInfo}><Clock size={16} /> Starts: {formatDate(startTime)} at {formatTime(startTime)}</p>
          <p className={styles.cardInfo}><Eye size={16} /> Countdown: {getCountdown(auction.start_time)}</p>
        </div>
      </Link>
    );
  };

  const GridView = () => (
    <div className={styles.grid}>
      {auctions.map(auction => (
        <AuctionCard key={auction.id} auction={auction} />
      ))}
    </div>
  );

  const CalendarView = () => {
    const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
    const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

    return (
      <div className={styles.calendar}>
        <div className={styles.calendarHeader}>
          <button onClick={() => setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1))} className={styles.calendarButton}>
            Previous
          </button>
          <h3 className={styles.calendarTitle}>{currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}</h3>
          <button onClick={() => setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1))} className={styles.calendarButton}>
            Next
          </button>
        </div>
        <div className={styles.calendarGrid}>
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
            <div key={day} className={styles.calendarDayHeader}>{day}</div>
          ))}
          {Array(firstDayOfMonth).fill(null).map((_, index) => (
            <div key={`empty-${index}`} className={`${styles.calendarDay} ${styles.calendarDayEmpty}`}></div>
          ))}
          {days.map(day => {
            const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
            const auctionsOnDay = auctions.filter(auction => {
              const auctionDate = new Date(auction.start_time);
              return auctionDate.getDate() === day && 
                     auctionDate.getMonth() === currentDate.getMonth() && 
                     auctionDate.getFullYear() === currentDate.getFullYear();
            });
            return (
              <div key={day} className={`${styles.calendarDay} ${auctionsOnDay.length > 0 ? styles.calendarDayHasAuctions : ''}`}>
                <span className={styles.dayNumber}>{day}</span>
                {auctionsOnDay.map(auction => (
                  <Link key={auction.id} to={`/auction/${auction.id}`} className={styles.calendarAuctionItem}>
                    <img src={productMap.get(auction.product_id)?.media_url || 'default-image-url'} alt={productMap.get(auction.product_id)?.title} className={styles.calendarAuctionItemImage} />
                    <span>{productMap.get(auction.product_id)?.title}</span>
                  </Link>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const ListView = () => (
    <div className={styles.list}>
      {auctions.map(auction => {
        const product = productMap.get(auction.product_id);
        if (!product) return null;
        const startTime = new Date(auction.start_time);
        return (
          <Link key={auction.id} to={`/auction/${auction.id}`} className={styles.listItem}>
            <div className={styles.imageContainer}>
              <img src={product.media_url || 'default-image-url'} alt={product.title} className={styles.listItemImage} />
              <span className={`${styles.status} ${styles.statusUpcoming}`}>Upcoming</span>
            </div>
            <div className={styles.listItemDetails}>
              <h3 className={styles.listItemTitle}>{product.title}</h3>
              <p className={styles.listItemInfo}><DollarSign size={16} /> Starting Bid: ${(auction.current_bid/100).toFixed(2)}</p>
              <p className={styles.listItemInfo}><Clock size={16} /> Starts: {formatDate(startTime)} at {formatTime(startTime)}</p>
              <p className={styles.listItemInfo}><Eye size={16} /> Countdown: {getCountdown(auction.start_time)}</p>
            </div>
          </Link>
        );
      })}
    </div>
  );

  if (loading) return <div className={styles.loading}>Loading upcoming auctions...</div>;
  if (error) return <div className={styles.error}>{error}</div>;

  return (
    <div className={styles.upcomingAuctions}>
      <h2 className={styles.title}>Upcoming Auctions</h2>
      <div className={styles.viewToggle}>
        <button onClick={() => setView('grid')} className={`${styles.viewToggleButton} ${view === 'grid' ? styles.active : ''}`}><Grid /></button>
        <button onClick={() => setView('calendar')} className={`${styles.viewToggleButton} ${view === 'calendar' ? styles.active : ''}`}><Calendar /></button>
        <button onClick={() => setView('list')} className={`${styles.viewToggleButton} ${view === 'list' ? styles.active : ''}`}><List /></button>
      </div>
      {view === 'grid' && <GridView />}
      {view === 'calendar' && <CalendarView />}
      {view === 'list' && <ListView />}
    </div>
  );
};

export default UpcomingAuctions;