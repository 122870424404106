// src/services/api.ts
import axios, { AxiosResponse } from 'axios';
import {
  User,
  Product,
  Auction,
  Bid,
  Invoice,
  Notification,
  Address,
  Review
} from './types';

const API_URL = process.env.REACT_APP_API_URL|| 'http://localhost:4000'; // Replace with your backend URL

export const getBidsbyAuction = async (id: number): Promise<any> => {
  return axios.get(`${API_URL}/auctions/bids/${id}`);
};

export const getAuctions = async (): Promise<AxiosResponse<Auction[]>> => {
  return axios.get(`${API_URL}/core/auctions`);
};

//emits a sync time event across all clients 
export const syncAuctionTimes = async (): Promise<any> => {
  try {
    const response = await axios.get(`${API_URL}/auctions/sync`);
    return response; // Return the data from the API response
  } catch (error) {
    console.error('Error synchronizing auction times:', error);
    throw error; // Propagate the error for handling in the component
  }
};
//verifies the time event with server boba
export const verifyAuction = async (id: number,uid:number): Promise<any> => {
  try {
    const response = await axios.get(`${API_URL}/auctions/verify/${id}/${uid}`);
    return response; // Return the data from the API response
  } catch (error) {
    console.error('Error synchronizing auction times:', error);
    throw error; // Propagate the error for handling in the component
  }
};

export const createBid = async (token:string, bidData: Omit<Bid, 'id'|'user_id'|'type'>): Promise<AxiosResponse<Bid>> => {
  return axios.post(`${API_URL}/auctions/bid`, bidData,{headers: {
    Authorization: `Bearer ${token}`
  }});
};



export const updateAuctionStatus = async (id: number, auctionData: Partial<Auction>): Promise<AxiosResponse<Auction>> => {
  return axios.put(`${API_URL}/auctions/status/${id}/${auctionData.status}`);
};


// Get auction by ID without auth
export const getAuctionById = async (id: number) => {
  return axios.get(`${API_URL}/core/auctions/${id}`);
};

// Fetch all products
export const getProducts = async (): Promise<AxiosResponse<Product[]>> => {
  return axios.get(`${API_URL}/core/products`);
};

// Get product by ID without auth
export const getProductById = async (id: number) => {
  return axios.get(`${API_URL}/core/products/${id}`);
};

// Fetch all reviews without auth
export const getReviews = async () => {
  return axios.get(`${API_URL}/core/reviews`);
};

// Fetch all bids without auth
export const getBids = async () => {
  return axios.get(`${API_URL}/core/bids`);
};

// Fetch all users without auth
export const getUsers = async () => {
  return axios.get(`${API_URL}/core/users`);
};

// Fetch all invoices without auth
export const getInvoices = async () => {
  return axios.get(`${API_URL}/core/invoices`);
};




export const getAddresses = async () => {
  return axios.get(`${API_URL}/core/addresses`);
};


export const postContact = async (name:string,email:string, subject:string,message:string):Promise<any> => {
  return axios.post(`${API_URL}/core/contact`, {name,email,subject,message});
};





export const randomNumber = async (): Promise<any> => {
  const response = await axios.get(`${API_URL}/auctions/sync`);
  return response.data
}