import React, { useState, useEffect } from 'react';
import { User } from '../../utils/types';
import { createUser, updateUser } from '../../utils/adminAPI';

interface UserFormProps {
  selectedUser: User | null;
  onSubmit: () => void;
}
const token = localStorage.getItem('token'); // or however you're storing the token


const UserForm: React.FC<UserFormProps> = ({ selectedUser, onSubmit }) => {
  const [formData, setFormData] = useState<Omit<User, 'id'>>({
    username: '',
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    password: '',
    credits: 0,
    is_active: true,
    reg_date: new Date().toISOString(),
  });
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (selectedUser) {
      setFormData({
        ...selectedUser,
        password: '', // Clear password when editing
      });
    } else {
      setFormData({
        username: '',
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        password: '',
        credits: 0,
        is_active: true,
        reg_date: new Date().toISOString(),
      });
    }
  }, [selectedUser]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: name === 'credits' ? parseInt(value, 10) : value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    try {
      if(token){
      if (selectedUser) {
        await updateUser(token,selectedUser.id, formData);
      } else {
        await createUser(token,formData);
      }
      onSubmit();}
    } catch (err: any) {
      if (err.response && err.response.status === 409) {
        setError('A user with this username or email already exists. Please choose a different one.');
      } else {
        setError('An error occurred while saving the user. Please try again.');
      }
      console.error('Error saving user', err);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && <div style={{ color: 'red', marginBottom: '10px' }}>{error}</div>}
      <input
        type="text"
        name="username"
        value={formData.username}
        onChange={handleChange}
        placeholder="Username"
        required
      />
      <input
        type="text"
        name="first_name"
        value={formData.first_name}
        onChange={handleChange}
        placeholder="First Name"
        required
      />
      <input
        type="text"
        name="last_name"
        value={formData.last_name}
        onChange={handleChange}
        placeholder="Last Name"
        required
      />
      <input
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        placeholder="Email"
        required
      />
      <input
        type="number"
        name="credits"
        value={formData.credits}
        onChange={handleChange}
        placeholder="Credits"
      />
      <input
        type="text"
        name="phone"
        value={formData.phone}
        onChange={handleChange}
        placeholder="Phone"
      />
      <input
        type="password"
        name="password"
        value={formData.password}
        onChange={handleChange}
        placeholder={selectedUser ? "Leave blank to keep current password" : "Password"}
        required={!selectedUser}
      />
      <button type="submit">{selectedUser ? 'Update User' : 'Create User'}</button>
    </form>
  );
};

export default UserForm;