import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAuctions, getProducts, getBids, getUsers, getReviews, getInvoices } from '../utils/coreAPI';
import { Auction, Product, Bid, User, Review, Invoice } from '../utils/types';
import { Star, User as UserIcon } from 'lucide-react';
import styles from './Winners.module.css';

const WinnersPage: React.FC = () => {
    const [completedAuctions, setCompletedAuctions] = useState<Auction[]>([]);
    const [products, setProducts] = useState<Map<number, Product>>(new Map());
    const [winners, setWinners] = useState<Map<number, Partial<User>>>(new Map());
    const [invoices, setInvoices] = useState<Map<number, Invoice>>(new Map());
    const [reviews, setReviews] = useState<Map<number, Review>>(new Map());
    const [uniqueBidders, setUniqueBidders] = useState<Map<number, number>>(new Map());
    const [filter, setFilter] = useState<'all' | 'reviewed' | 'non-reviewed'>('all');
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [auctionsResponse, productsResponse, bidsResponse, usersResponse, reviewsResponse, invoicesResponse] = await Promise.all([
                    getAuctions(),
                    getProducts(),
                    getBids(),
                    getUsers(),
                    getReviews(),
                    getInvoices(),
                ]);
                
                const filteredAuctions = auctionsResponse.data.filter((auction: Auction) => auction.status === 'completed');
                setCompletedAuctions(filteredAuctions);

                const productMap = new Map(productsResponse.data.map((product: Product) => [product.id, product]));
                setProducts(productMap);

                const winnerMap = new Map();
                const invoiceMap = new Map();
                const reviewMap = new Map();
                const uniqueBiddersMap = new Map();

                filteredAuctions.forEach((auction: Auction) => {
                    const auctionInvoice = invoicesResponse.data.find((invoice: Invoice) => invoice.auction_id === auction.id);
                    if (auctionInvoice) {
                        const winner = usersResponse.data.find((user: User) => user.id === auctionInvoice.user_id);
                        if (winner) {
                            winnerMap.set(auction.id, winner);
                            invoiceMap.set(auction.id, auctionInvoice);
                        }
                    }

                    const auctionBids = bidsResponse.data.filter((bid: Bid) => bid.auction_id === auction.id);
                    const uniqueBidderCount = new Set(auctionBids.map((bid: Bid) => bid.user_id)).size;
                    uniqueBiddersMap.set(auction.id, uniqueBidderCount);

                    const auctionReview = reviewsResponse.data.find((review: Review) => review.auction_id === auction.id);
                    if (auctionReview) {
                        reviewMap.set(auction.id, auctionReview);
                    }
                });

                setWinners(winnerMap);
                setInvoices(invoiceMap);
                setReviews(reviewMap);
                setUniqueBidders(uniqueBiddersMap);
            
            } catch (error) {
                console.log('Failed to fetch data', error);
            }
        };

        fetchData();
    }, []);

    const renderStars = (rating: number) => {
        return Array(5).fill(0).map((_, i) => (
            <Star key={i} fill={i < rating ? "#FFD700" : "none"} stroke="#FFD700" />
        ));
    };

    const handleFilterChange = (filterType: 'all' | 'reviewed' | 'non-reviewed') => {
        setFilter(filterType);
    };

    const filteredAuctions = completedAuctions.filter((auction) => {
        const hasReview = reviews.has(auction.id);
        if (filter === 'reviewed') return hasReview;
        if (filter === 'non-reviewed') return !hasReview;
        return true;
    });

    return (
        <div className={styles.winnersPage}>
            <h1 className={styles.pageTitle}>Recent Winners</h1>
            <div className={styles.filterButtons}>
                <button
                    className={`${styles.filterButton} ${filter === 'all' ? styles.active : ''}`}
                    onClick={() => handleFilterChange('all')}
                >
                    All
                </button>
                <button
                    className={`${styles.filterButton} ${filter === 'reviewed' ? styles.active : ''}`}
                    onClick={() => handleFilterChange('reviewed')}
                >
                    Reviewed
                </button>
                <button
                    className={`${styles.filterButton} ${filter === 'non-reviewed' ? styles.active : ''}`}
                    onClick={() => handleFilterChange('non-reviewed')}
                >
                    Non-reviewed
                </button>
            </div>
            <div className={styles.auctionGrid}>
                {filteredAuctions.map((auction) => {
                    const product = products.get(auction.product_id);
                    const winner = winners.get(auction.id);
                    const invoice = invoices.get(auction.id);
                    const review = reviews.get(auction.id);
                    const uniqueBidderCount = uniqueBidders.get(auction.id);
                    const price = (auction.current_bid / 100).toFixed(2);

                    if (!product || !winner || !invoice) return null;

                    return (
                        <Link to={`/auction/${auction.id}`} key={auction.id} className={styles.cardLink}>
                            <div className={styles.card}>
                                <div className={styles.imageContainer}>
                                    {review && review.image_url ? (
                                        <img src={`${review.image_url}`} alt="User review" className={styles.image} />
                                    ) : (
                                        <img src={product.media_url || '/default-image.jpg'} alt={product.title} className={styles.image} />
                                    )}
                                </div>
                                <div className={styles.details}>
                                    <div className={styles.header}>
                                        <img src={product.media_url || '/default-image.jpg'} alt={product.title} className={styles.thumbnail} />
                                        {review && (
                                            <div className={styles.reviewSection}>
                                                <div className={styles.reviewRating}>
                                                    {renderStars(review.rating)}
                                                </div>
                                                <p className={styles.reviewText}>{review.review_text}</p>
                                            </div>
                                        )}
                                    </div>
                                    <h3 className={styles.productTitle}>{product.title}</h3>
                                    <div className={styles.winnerInfo}>
                                        <UserIcon size={18} />
                                        <span>{winner.username}</span>
                                    </div>
                                    <table className={styles.auctionInfo}>
                                        <tbody>
                                            <tr>
                                                <td><strong>Final Bid:</strong></td>
                                                <td>£{price}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Retail Price:</strong></td>
                                                <td><s>£{product.rrp}</s></td>
                                            </tr>
                                            <tr>
                                                <td><strong>Savings:</strong></td>
                                                <td>£{(product.rrp - Number(price)).toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>End Date:</strong></td>
                                                <td>{new Date(auction.start_time).toLocaleDateString()}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Participants:</strong></td>
                                                <td>{uniqueBidderCount}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default WinnersPage;