// CreditsSection.tsx
import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { addCredits, getUserInvoices, createPaymentIntent } from '../utils/accountAPI';
import { Invoice } from '../utils/types';
import styles from './CreditForm.module.css'; // Import your CSS module

const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY || '';
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const CREDITS_TO_COST_MAP: { [credits: number]: number } = {
    50: 25,
    120: 50,
    200: 75,
    275: 100,
    1000: 275,
};

interface CreditsSectionProps {
    token: string;
    currentCredits: number;
    onCreditsAdded: (newCredits: number) => void;
}

const PaymentForm: React.FC<{ amount: number; onPaymentSuccess: () => void }> = ({ amount, onPaymentSuccess }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isProcessing, setIsProcessing] = useState(false);
    const [paymentError, setPaymentError] = useState('');

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!stripe || !elements) return;
        setIsProcessing(true);
        try {
            const { clientSecret } = await createPaymentIntent(amount, 'GBP', -1);
            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: { card: elements.getElement(CardElement)! },
            });
            if (result.error) {
                setPaymentError(result.error.message || 'An error occurred during payment.');
            } else {
                onPaymentSuccess();
            }
        } catch (error) {
            setPaymentError('An error occurred while processing your payment.');
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.paymentForm}>
            <div className={styles.cardElementContainer}>
                <CardElement className={styles.cardElement} />
            </div>
            {paymentError && <div className={styles.paymentError}>{paymentError}</div>}
            <button type="submit" disabled={isProcessing || !stripe} className={styles.bidButton}>
                {isProcessing ? 'Processing...' : `Pay £${amount}`}
            </button>
        </form>
    );
};

const CreditsSection: React.FC<CreditsSectionProps> = ({ token, currentCredits, onCreditsAdded }) => {
    const [selectedCredits, setSelectedCredits] = useState<number>(0);
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [invoicesLoading, setInvoicesLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [isHistoryOpen, setIsHistoryOpen] = useState<boolean>(false);

    useEffect(() => {
        fetchInvoices();
    }, []);

    const fetchInvoices = async () => {
        setInvoicesLoading(true);
        setError('');
        try {
            const data = await getUserInvoices(token);
            const creditInv = data.filter(invoice => invoice.credits != null && invoice.credits > 0);

            setInvoices(creditInv);
        } catch (err) {
            setError('Failed to fetch invoices');
        } finally {
            setInvoicesLoading(false);
        }
    };

    const handleCreditsChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCredits(parseInt(event.target.value, 10));
    };

    const handlePaymentSuccess = async () => {
        setError('');
        setSuccessMessage('');
        setLoading(true);
        try {
            const response = await addCredits(token, selectedCredits);
            setSuccessMessage('Credits added successfully');
            if (response.data.credits && typeof response.data.credits === 'number') {
                onCreditsAdded(response.data.credits);
            }
            fetchInvoices();
        } catch (err: any) {
            setError(err.response?.data?.message || 'Failed to add credits');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles.creditsSection}>
            <h2 className={styles.sectionTitle}>Credit Management</h2>
            <div className={styles.creditsDisplay}>
                <span className={styles.creditsLabel}>Available Credits:</span>
                <span className={styles.creditsAmount}>{currentCredits}</span>
            </div>

            {successMessage && <div className={styles.successMessage}>{successMessage}</div>}
            {error && <div className={styles.errorMessage}>{error}</div>}

            <div className={styles.creditsForm}>
                <select onChange={handleCreditsChange} className={styles.creditsSelect}>
                    <option value="0">Choose top-up amount</option>
                    {Object.entries(CREDITS_TO_COST_MAP).map(([credits, cost]) => (
                        <option key={credits} value={credits}>
                            £{cost} - {credits} Credits
                        </option>
                    ))}
                </select>

                {selectedCredits > 0 && (
                    <Elements stripe={stripePromise}>
                        <PaymentForm
                            amount={CREDITS_TO_COST_MAP[selectedCredits]}
                            onPaymentSuccess={handlePaymentSuccess}
                        />
                    </Elements>
                )}
            </div>

            <div className={styles.historySection}>
                <button onClick={() => setIsHistoryOpen(!isHistoryOpen)} className={styles.historyToggle}>
                    Credits Top-Up History
                    <span className={styles.toggleIcon}>{isHistoryOpen ? '▲' : '▼'}</span>
                </button>
            </div>

            {isHistoryOpen && (
                <div className={styles.invoiceHistory}>
                    {invoicesLoading ? (
                        <p>Loading invoices...</p>
                    ) : invoices.length === 0 ? (
                        <p>No credit top-up history available.</p>
                    ) : (
                        <table className={styles.invoiceTable}>
                            <thead>
                                <tr>
                                    <th>Invoice ID</th>
                                    <th>Amount (£)</th>
                                    <th>Credits</th>
                                    <th>Status</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoices.map(invoice => (
                                    <tr key={invoice.id}>
                                        <td>{invoice.id}</td>
                                        <td>{invoice.amount}</td>
                                        <td>{invoice.credits}</td>
                                        <td>{invoice.status}</td>
                                        <td>{new Date(invoice.date).toLocaleDateString()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            )}
        </div>
    );
};

export default CreditsSection;
