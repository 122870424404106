// src/components/ErrorPopup.tsx

import React, { useEffect } from 'react';
import { X, AlertTriangle, AlertCircle, Info } from 'lucide-react';
import '../styles/components/ErrorPopup.css';

interface ErrorPopupProps {
  message: string;
  onClose: () => void;
  type?: 'error' | 'warning' | 'info';
}

const ErrorPopup: React.FC<ErrorPopupProps> = ({ message, onClose, type = 'error' }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000); // Auto-close after 5 seconds

    return () => clearTimeout(timer);
  }, [onClose]);

  const getIcon = () => {
    switch (type) {
      case 'error':
        return <AlertCircle size={24} />;
      case 'warning':
        return <AlertTriangle size={24} />;
      case 'info':
        return <Info size={24} />;
    }
  };

  return (
    <div className={`error-popup ${type}`} role="alert">
      <div className="error-popup-content">
        {getIcon()}
        <p>{message}</p>
        <button onClick={onClose} className="close-button" aria-label="Close">
          <X size={24} />
        </button>
      </div>
    </div>
  );
};

export default ErrorPopup;