import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface FAQItem {
  question: string;
  answer: string;
}

const faqData: FAQItem[] = [
  {
    question: "How does UShop's auction system work?",
    answer: "UShop uses a unique bidding system where each bid increases the auction price by a small amount and resets the countdown timer. The last bidder when the timer reaches zero wins the auction."
  },
  {
    question: "How do I place a bid?",
    answer: "To place a bid, you need to be registered and logged in. On the auction page, click the 'Place Bid' button. You'll be prompted to confirm your bid before it's placed."
  },
  {
    question: "What happens if I win an auction?",
    answer: "If you win an auction, you'll receive a notification via email and on your account dashboard. You'll then need to complete the payment for your winning bid within 24 hours."
  },
  {
    question: "How do I pay for my winning bid?",
    answer: "We accept various payment methods including credit/debit cards and PayPal. After winning an auction, you can proceed to checkout from your account dashboard or the email notification you receive."
  },
  {
    question: "What is your shipping policy?",
    answer: "We offer worldwide shipping. Shipping costs are calculated based on your location and the item's size and weight. These costs are displayed before you confirm your purchase."
  },
  {
    question: "Can I cancel my bid?",
    answer: "Once placed, bids cannot be cancelled. Please ensure you're certain before placing a bid."
  },
  {
    question: "What is your return policy?",
    answer: "We offer a 30-day return policy for most items. If you're not satisfied with your purchase, you can return it for a full refund, minus shipping costs. Some restrictions apply to certain products."
  },
  {
    question: "How do I track my order?",
    answer: "Once your order is shipped, you'll receive a tracking number via email. You can also find this information in your account dashboard under 'Order History'."
  },
  {
    question: "Are the products new?",
    answer: "Unless explicitly stated otherwise, all products auctioned on UShop are brand new and come with full manufacturer warranty where applicable."
  },
  {
    question: "How can I contact customer support?",
    answer: "You can reach our customer support team via email at support@ushop.bid, or through the contact form on our Contact Us page. Our support hours are Monday to Friday, 9 AM to 5 PM EST."
  }
];

const FAQPage: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleQuestion = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Frequently Asked Questions</h1>
      <div style={styles.faqList}>
        {faqData.map((item, index) => (
          <div key={index} style={styles.faqItem}>
            <div 
              style={styles.question} 
              onClick={() => toggleQuestion(index)}
            >
              <span>{item.question}</span>
              {openIndex === index ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </div>
            {openIndex === index && (
              <div style={styles.answer}>
                {item.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Roboto, sans-serif',
  } as React.CSSProperties,
  title: {
    fontSize: '32px',
    color: '#e53e3e',
    textAlign: 'center',
    marginBottom: '40px',
  } as React.CSSProperties,
  faqList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  } as React.CSSProperties,
  faqItem: {
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  } as React.CSSProperties,
  question: {
    backgroundColor: '#f8f8f8',
    padding: '15px 20px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease',
  } as React.CSSProperties,
  answer: {
    padding: '20px',
    backgroundColor: '#ffffff',
    lineHeight: '1.6',
  } as React.CSSProperties,
};

export default FAQPage;