import React, { useState, useMemo } from 'react';
import { User } from '../../utils/types';
import { deleteUser } from '../../utils/adminAPI';
// import './inv.css';

interface UserListProps {
  users: User[];
  onEdit: (user: User) => void;
  fetchUsers: () => void;
}

const UserList: React.FC<UserListProps> = ({ users, onEdit, fetchUsers }) => {
  const [searchText, setSearchText] = useState('');
  const [sortColumn, setSortColumn] = useState<keyof User | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const token = localStorage.getItem('token'); // or however you're storing the token

  const handleDelete = async (id: number) => {
    if(token)
    await deleteUser(token,id);
    fetchUsers();
  };

  const handleSort = (column: keyof User) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const filteredAndSortedUsers = useMemo(() => {
    return users
      .filter(user => 
        Object.values(user).some(value => 
          value?.toString().toLowerCase().includes(searchText.toLowerCase())
        )
      )
      .sort((a, b) => {
        if (!sortColumn) return 0;
        if (! a) return 0;
        if (! b[sortColumn]) return 0;

        if ( a[sortColumn] || 0 < Number(b[sortColumn]) || 0) return sortDirection === 'asc' ? -1 : 1;
        if ( a[sortColumn]||0  >Number(b[sortColumn]) || 0 ) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
  }, [users, searchText, sortColumn, sortDirection]);

  const clearAll = () => {
    setSearchText('');
    setSortColumn(null);
    setSortDirection('asc');
  };

  return (
    <div className="admin-list">
      <h2>User List</h2>
      <div className="admin-list__controls">
        <input
          type="text"
          placeholder="Search users"
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          className="admin-list__search"
        />
        <button onClick={clearAll} className="admin-list__clear-btn">Clear filters and sorters</button>
      </div>
      <table className="admin-list__table">
        <thead>
          <tr>
            <th onClick={() => handleSort('id')}>
              ID {sortColumn === 'id' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('username')}>
              Username {sortColumn === 'username' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('email')}>
              Email {sortColumn === 'email' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('credits')}>
              Credits {sortColumn === 'credits' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('is_active')}>
              Active {sortColumn === 'is_active' && (sortDirection === 'asc' ? '▲' : '▼')}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredAndSortedUsers.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>{user.credits}</td>
              <td>{user.is_active ? 'Yes' : 'No'}</td>
              <td>
                <button onClick={() => onEdit(user)} className="admin-list__edit-btn">Edit</button>
                <button onClick={() => handleDelete(user.id)} className="admin-list__delete-btn">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserList;