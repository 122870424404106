import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { getAuctions, getProducts, getBids } from '../../utils/coreAPI';
import { Auction, Product, Bid } from '../../utils/types';
import { createBid, syncAuctionTimes, verifyAuction, updateAuctionStatus } from '../../utils/coreAPI';
import io from 'socket.io-client';
import styles from './AuctionGovernor.module.css';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
const socketUrl = apiUrl.endsWith('/api') ? apiUrl.slice(0, -4) : apiUrl;
const socket = io(socketUrl);

const useAuctionData = () => {
  const [auctions, setAuctions] = useState<Auction[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [bids, setBids] = useState<Bid[]>([]);
  const [timers, setTimers] = useState<Map<number, number>>(new Map());


  useEffect(() => {
    const fetchData = async () => {
      try {
        const [auctionsResponse, productsResponse, bidsResponse, syncTimesResponse] = await Promise.all([
          getAuctions(),
          getProducts(),
          getBids(),
          syncAuctionTimes()
        ]);

        const filteredAuctions = auctionsResponse.data.filter((auction: Auction) =>
          auction.status === 'upcoming' || auction.status === 'active'
        );

        setAuctions(filteredAuctions);
        setProducts(productsResponse.data);
        setBids(bidsResponse.data);

        return { auctions: filteredAuctions, syncTimesResponse };
      } catch (error) {
        console.log('Failed to fetch data', error);
        return { auctions: [], syncTimesResponse: {} };
      }
    };

    fetchData().then(({ auctions, syncTimesResponse }) => {
      const initialTimers = new Map<number, number>();
      auctions.forEach((auction: Auction) => {
        initialTimers.set(auction.id, syncTimesResponse[auction.id]?.remainingTime || 0);
      });
      setTimers(initialTimers);
    });
  }, []);

  return { auctions, setAuctions, products, bids, setBids, timers, setTimers };
};

const AuctionGovernor: React.FC = () => {
  const { auctions, setAuctions, products, bids, setBids, timers, setTimers } = useAuctionData();
  const [prevUsernames, setPrevUsernames] = useState<Map<number, string>>(new Map());
  const [nextVerifyUids, setNextVerifyUids] = useState<Map<number, number>>(new Map());
  const [verifyTimes, setVerifyTimes] = useState<Map<number, number>>(new Map());
  const token = localStorage.getItem('token');
  const [auctionsSynced, setAuctionsSynced] = useState<boolean>(false);

  const handleAuctionUpdate = useCallback((updatedAuction: Auction) => {
    setAuctions((prevAuctions) => {
      const auctionExists = prevAuctions.some((auction) => auction.id === updatedAuction.id);
      if (auctionExists) {
        return prevAuctions
          .map((auction) => auction.id === updatedAuction.id ? { ...auction, status: updatedAuction.status } : auction)
          .filter((auction) => auction.status !== 'completed');
      } else {
        return [...prevAuctions, updatedAuction].filter((auction) => auction.status !== 'completed');
      }
    });
  }, []);

  useEffect(() => {
    socket.on('auctionUpdate', handleAuctionUpdate);
    return () => {
      socket.off('auctionUpdate');
    };
  }, [handleAuctionUpdate]);

  useEffect(() => {
    const currentTime = new Date().getTime();
    auctions.forEach(async (auction) => {
      const auctionStartTime = new Date(auction.start_time).getTime();
      const elapsed = Math.floor((currentTime - auctionStartTime) / 1000);
      if (elapsed >= 0 && auction.status === 'upcoming') {
        await updateAuctionStatus(auction.id, { ...auction, status: 'active' });
        setAuctions((prevAuctions) =>
          prevAuctions.map((a) => (a.id === auction.id ? { ...a, status: 'active' } : a))
        );
      }
    });
  }, [auctions]);

  const handleTimerTick = useCallback(() => {
    setTimers((prevTimers) => {
      const newTimers = new Map(prevTimers);
      prevTimers.forEach((time, auctionId) => {
        if (time === 0) {
          const auction = auctions.find((a) => a.id === auctionId);
          if (auction && auction.current_bid >= auction.umin) {
            if (!auctionsSynced) return;

            updateAuctionStatus(auctionId, { status: 'completed' });
            newTimers.delete(auctionId);
          }
        } else {
          const newTime = time - 1;
          newTimers.set(auctionId, newTime);
          const verifyTime = verifyTimes.get(auctionId);
          if (verifyTime !== undefined && newTime <= verifyTime) {
            const uidToVerify = nextVerifyUids.get(auctionId) || 0;
            verifyAuction(auctionId, uidToVerify);
            setVerifyTimes(prev => {
              const newVerifyTimes = new Map(prev);
              newVerifyTimes.delete(auctionId);
              return newVerifyTimes;
            });
          }
        }
      });
      return newTimers;
    });
  }, [auctions, nextVerifyUids, verifyTimes]);

  useEffect(() => {
    const interval = setInterval(handleTimerTick, 1000);
    return () => clearInterval(interval);
  }, [handleTimerTick]);

  const handleBidPlaced = useCallback((data: { auction: Auction; bid: Bid; uid: number; username: string }) => {
    const { auction, bid, uid, username } = data;
    setAuctions((prevAuctions) =>
      prevAuctions.map((a) => a.id === auction.id ? { ...a, current_bid: auction.current_bid + 1 } : a)
    );
    setBids((prevBids) => [...prevBids, bid]);
    setTimers((prevTimers) => new Map(prevTimers).set(auction.id, auction.countdown_timer));
    setPrevUsernames((prev) => new Map(prev).set(auction.id, username));
    setNextVerifyUids((prev) => new Map(prev).set(auction.id, uid));
    setVerifyTimes((prev) => {
      const newVerifyTimes = new Map(prev);
      const countdown = auction.countdown_timer;
      const minTime = Math.floor(countdown * 0.25);
      const maxTime = Math.floor(countdown * 0.85);
      const randomVerifyTime = Math.floor(Math.random() * (maxTime - minTime + 1)) + minTime;
      newVerifyTimes.set(auction.id, randomVerifyTime);
      return newVerifyTimes;
    });
  }, []);

  useEffect(() => {
    socket.on('bidPlaced', handleBidPlaced);
    return () => {
      socket.off('bidPlaced');
    };
  }, [handleBidPlaced]);

  useEffect(() => {
    socket.on('auctionSync', (data) => {
      if (Array.isArray(data)) {
        setTimers((prevTimers) => {
          const newTimers = new Map(prevTimers);
          data.forEach(({ auction_id, remainingTime }) => {
            newTimers.set(auction_id, remainingTime);
          });
          setAuctionsSynced(true);

          return newTimers;
        });
      }
    });
    return () => {
      socket.off('auctionSync');
    };
  }, []);

  const handleBid = useCallback(async (auctionId: number) => {
    try {
      const auction = auctions.find((auction) => auction.id === auctionId);
      if (!auction || auction.status !== 'active') return;
      await createBid(token as string, {
        auction_id: auctionId,
        bid_time: new Date().toISOString(),
      });
      setAuctions((prevAuctions) =>
        prevAuctions.map((a) => a.id === auctionId ? { ...a, current_bid: a.current_bid + 1 } : a)
      );
    } catch (error) {
      console.error('Failed to place bid', error);
    }
  }, [auctions, token]);

  const countBidsByType = useCallback((auctionId: number) => {
    const auctionBids = bids.filter(bid => bid.auction_id === auctionId);
    const VTypeCount = auctionBids.filter(bid => bid.type === 'V').length;
    const UTypeCount = auctionBids.filter(bid => bid.type === 'U').length;
    return { VTypeCount, UTypeCount };
  }, [bids]);

  const productMap = useMemo(() => {
    const map = new Map<number, Product>();
    products.forEach((product) => map.set(product.id, product));
    return map;
  }, [products]);

  return (
    <div className={styles.auctionGrid}>
      {auctions.map((auction) => {
        const product = productMap.get(auction.product_id);
        const remainingTime = timers.get(auction.id) || 0;
        const isActive = auction.status === 'active';
        const startTime = new Date(auction.start_time).getTime();
        const now = new Date().getTime();
        const countdown = Math.max(Math.floor((startTime - now) / 1000), 0);
        const { VTypeCount, UTypeCount } = countBidsByType(auction.id);
        const prevUsername = prevUsernames.get(auction.id) || 'None';
        const nextVerifyUid = nextVerifyUids.get(auction.id) || 0;
        const verifyTime = verifyTimes.get(auction.id);

        if (!product) return null;

        return (
          <div key={auction.id} className={styles.auctionCard}>
            <img src={product.media_url || 'default-image-url'} alt={product.title || 'Product Image'} className={styles.auctionImage} />
            <div className={styles.auctionDetails}>
              <h3>{product.title}</h3>
              <p>{product.description}</p>
              <p>Starting Bid: ${auction.current_bid || 0}</p>
              <p>Status: {auction.status}</p>
              <p>Start Time: {new Date(auction.start_time).toLocaleString()}</p>
              {isActive ? (
                <p>Time Remaining: {remainingTime}s</p>
              ) : (
                <p>Countdown Timer: {auction.countdown_timer}s</p>
              )}
              {isActive ? (
                <button onClick={() => handleBid(auction.id)} className={styles.bidButton}>Bid</button>
              ) : (
                <p>Countdown to Start: {countdown}s</p>
              )}
              <p>UMIN: {auction.umin}</p>
              <p>BMAX: {auction.bmax}</p>
              <p>Bids (V Type): {VTypeCount}</p>
              <p>Bids (U Type): {UTypeCount}</p>
              <p>Previous Bidder: {prevUsername}</p>
              <p>Next ID: {nextVerifyUid} at: {verifyTime}s left</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AuctionGovernor;