import React, { useState, useEffect } from 'react';
import { Invoice } from '../../utils/types';
import { createInvoice, updateInvoice } from '../../utils/adminAPI';
import './inv.css'; // Ensure the path is correct based on your directory structure

interface InvoiceFormProps {
  selectedInvoice: Invoice | null;
  onSubmit: () => void;
}

export const InvoiceForm: React.FC<InvoiceFormProps> = ({ selectedInvoice, onSubmit }) => {
  const [formData, setFormData] = useState<Invoice>({
    id: 0,
    user_id: undefined,
    amount: 0,
    auction_id: null,
    credits: 0,
    status: 'awaiting payment',
    date: new Date().toISOString().split('T')[0],
  });
  const token = localStorage.getItem('token'); // or however you're storing the token

  useEffect(() => {
    if (selectedInvoice) {
      setFormData({ ...selectedInvoice });
    } else {
      setFormData({
        id: 0,
        user_id: undefined,
        amount: 0,
        auction_id: null,
        credits: 0,
        status: 'awaiting payment',
        date: new Date().toISOString().split('T')[0],
      });
    }
  }, [selectedInvoice]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const value = e.target.type === 'number' ? Number(e.target.value) : e.target.value;
    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if(token) {
    if (selectedInvoice) {
      await updateInvoice(token,selectedInvoice.id, formData);
    } else {
      await createInvoice(token,formData);
    }
    onSubmit();}
  };

  return (
    <form onSubmit={handleSubmit} className="invoice-form">
      <div className="form-group">
        <label htmlFor="user_id">User ID:</label>
        <input
          type="number"
          id="user_id"
          name="user_id"
          value={formData.user_id || ''}
          onChange={handleChange}
          placeholder="User ID"
        />
      </div>

      <div className="form-group">
        <label htmlFor="amount">Amount:</label>
        <input
          type="number"
          id="amount"
          name="amount"
          value={formData.amount}
          onChange={handleChange}
          placeholder="Amount"
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="auction_id">Auction ID:</label>
        <input
          type="number"
          id="auction_id"
          name="auction_id"
          value={formData.auction_id || ''}
          onChange={handleChange}
          placeholder="Auction ID"
        />
      </div>

      <div className="form-group">
        <label htmlFor="credits">Credits:</label>
        <input
          type="number"
          id="credits"
          name="credits"
          value={formData.credits || 0}
          onChange={handleChange}
          placeholder="Credits"
        />
      </div>

      <div className="form-group">
        <label htmlFor="status">Status:</label>
        <select 
          id="status"
          name="status" 
          value={formData.status} 
          onChange={handleChange}
        >
          <option value="awaiting payment">Awaiting Payment</option>
          <option value="paid">Paid</option>
          <option value="shipped">Shipped</option>
          <option value="delivered">Delivered</option>
          <option value="completed">Completed</option>
          <option value="cancelled">Cancelled</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="date">Date:</label>
        <input
          type="date"
          id="date"
          name="date"
          value={formData.date}
          onChange={handleChange}
          required
        />
      </div>

      <button type="submit" className="submit-button">
        {selectedInvoice ? 'Update Invoice' : 'Create Invoice'}
      </button>
    </form>
  );
};

export default InvoiceForm;