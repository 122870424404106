// src/services/api.ts
import axios, { AxiosResponse } from 'axios';
import {
    User,
    Product,
    Auction,
    Bid,
    Invoice,
    Notification,
    Address,
    Review
} from './types';

const API_URL = process.env.REACT_APP_API_URL|| 'http://localhost:4000'; // Replace with your backend URL

//Modular Invoice Generator 
interface InvoiceData {
    invoiceNumber: string|'';
    date: string;
    customer: User;
    shippingAddress: Address;
    item: {
        description: string;
        quantity: number;
        unitPrice: number;
    };
    postageAndPackaging: number;
    auction: Auction;
}
export interface LoginResponse {
    token: string;
    user: {
        id: number;
        email: string;
    };
}
interface RegisterResponse {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    phone?: string;
    address: Address;
    credits: number;
    reg_date?: string;
    last_login?: string;
    is_active: boolean;
    type?: number;
}
interface RegisterResponse {
    user: User;
    address: Address;
}

export const checkUserFields = async (fields: { username?: string; email?: string; phone?: string }) => {
    try {
      const response = await axios.post(`${API_URL}/auth/check-user`, fields);
      return response.data;
    } catch (error) {
      throw error;
    }
  };


export const getUserNotifs = async (token: string): Promise<Notification[]> => {
    const response = await axios.get(
        `${API_URL}/account/notifications`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }
    );
    // Assuming the backend filters invoices where credits is not null
    return response.data;
};

export const readNotification = async (token: string, notificationId: number): Promise<Notification> => {
    try {
        const response = await axios.put(
            `${API_URL}/account/notifications/`,
            { notificationId: notificationId },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error('Error marking notification as read:', error);
        throw error;
    }
};


export const deleteNotification = async (token: string, notificationId: number): Promise<Notification> => {
    try {
        const response = await axios.post(
            `${API_URL}/account/notifications/`,
            { notificationId: notificationId },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error('Error marking notification as read:', error);
        throw error;
    }
};


export const loginUser = async (email: string, password: string): Promise<AxiosResponse<LoginResponse>> => {
    return axios.post(`${API_URL}/auth/login`, { email, password });
};

export const registerUser = async (
    user: Omit<User, 'id' | 'is_active' | 'credits' | 'reg_date'>,
    address: Omit<Address, 'id'>
): Promise<AxiosResponse<RegisterResponse>> => {
    return axios.post(`${API_URL}/auth/register`, {
        user,
        address
    });
};

export const getAccountDetails = async (token: string): Promise<AxiosResponse<User>> => {
    return axios.get(`${API_URL}/account`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

export const updateAccountDetails = async (token: string, userData: Partial<User>): Promise<AxiosResponse<User>> => {
    return axios.put(`${API_URL}/account`, userData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

export const getAddressDetails = async (token: string): Promise<AxiosResponse<Address[]>> => {
    return axios.get(`${API_URL}/account/addresses`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

export const updateAddressDetails = async (token: string, address: Partial<Address>): Promise<AxiosResponse<Address>> => {
    return axios.put(`${API_URL}/account/addresses`, address, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

export const getUserInvoices = async (token: string): Promise<Invoice[]> => {
    const response = await axios.get(
        `${API_URL}/account/invoices`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }
    );
    // Assuming the backend filters invoices where credits is not null
    return response.data;
};


export const getUserBiddingHistory = async (token: string): Promise<any[]> => {
    const response = await axios.get(
        `${API_URL}/account/bids`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }
    );
    // Assuming the backend filters invoices where credits is not null
    return response.data;
};


export const addCredits = async (token: string, credits: number): Promise<AxiosResponse<User>> => {
    return axios.post(`${API_URL}/account`, { 'credits': credits }, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

export const submitReview = async (token: string, auctionId: number, formData: FormData): Promise<Review> => {
    formData.append('auctionId', auctionId.toString());
    return axios.post(`${API_URL}/account/review`, formData, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    });
};



export const createPaymentIntent = async (amount: number, currency: string, invoiceId: number) => {
    const { data } = await axios.post(`${API_URL}/payments/create-intent`, {
        amount,
        currency,
        invoiceId, // The ID of the invoice being paid

    });
    return data;
};
export const sendOTP = async (contact: string, type: 'email' | 'phone'): Promise<void> => {

    const { data } = await axios.post(`${API_URL}/auth/send-otp`, {
        contact,
        type,
    });
    return data;
};

export const verifyOTP = async (contact: string, otp: string, type: 'email' | 'phone'): Promise<boolean> => {


    const { data } = await axios.post(`${API_URL}/auth/verify-otp`, {
        contact,
        otp,
        type,
    });
    return data.verified;

};





async function generateInvoice(invoice: Invoice, user: User, product: Product, auction: Auction, shippingAddress: Address,billingAddress: Address) {
    try {
        const invoiceData = {
            invoiceNumber: `INV-${invoice.id.toString().padStart(6, '0')}`,
            date: invoice.date,
            customer: user,
            shippingAddress,
            billingAddress,
            item: {
                description: product.title,
                quantity: 1,
                unitPrice: auction.current_bid,
            },
            postageAndPackaging: product.postage,
            auction,
            amount: invoice.amount,
            status: invoice.status
        };

        const response = await axios.post(`${API_URL}/invoice/generate-invoice`, invoiceData);
        if (response.data.success) {
            window.open(`${API_URL}/invoice/download-invoice/${response.data.filename}`, '_blank');
        } else {
            throw new Error('Failed to generate invoice');
        }
    } catch (error) {
        console.error('Error generating invoice:', error);
        alert('Failed to generate invoice. Please try again.');
    }
}

export default generateInvoice;