import React, { useEffect, useState, useCallback } from 'react';
import { getAccountDetails, updateAccountDetails, updateAddressDetails, getAddressDetails } from '../utils/accountAPI';
import { User, Address } from '../utils/types';
import CreditsSection from './CreditForm';
import BiddingHistory from './BiddingHistory';
import { useNavigate } from 'react-router-dom';
import WonAuctions from './WonOrderForm';
import { deleteUser } from '../utils/adminAPI';
import styles from './AccountPage.module.css';

const AccountPage: React.FC = () => {
  const [user, setUser] = useState<Partial<User>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const token = localStorage.getItem('token') || '';
  const [activeSection, setActiveSection] = useState<string>('accountDetails');
  const [activeSubSection, setActiveSubSection] = useState<string | null>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteConfirmationStep, setDeleteConfirmationStep] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      fetchAccountDetails();
    }
  }, [token]);

  const fetchAccountDetails = async () => {
    setLoading(true);
    try {
      const response = await getAccountDetails(token);
      setUser(response.data);
      setLoading(false);
      setError('');
    } catch (err) {
      setError('Failed to fetch account details');
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser(prevUser => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleAccSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccessMessage('');
    try {
      const response = await updateAccountDetails(token, user);
      setUser(response.data);
      setSuccessMessage('Account updated successfully');
    } catch (err) {
      setError('Failed to update account');
    } finally {
      setLoading(false);
    }
  };

  const handleSectionChange = (section: string) => {
    setActiveSection(section);
    setActiveSubSection(null);
  };

  const handleSubSectionChange = (subSection: string) => {
    setActiveSubSection(activeSubSection === subSection ? null : subSection);
  };

  const handleLogout = useCallback(() => {
    localStorage.removeItem('token');
    navigate('/');
  }, [navigate]);

  const handleDeleteAccount = async () => {
    if (deleteConfirmationStep < 2) {
      setDeleteConfirmationStep(prevStep => prevStep + 1);
    } else {
      try {
        if(token)
        await deleteUser(token,user.id as number);
        localStorage.removeItem('token');
        navigate('/');
      } catch (err) {
        setError('Failed to delete account');
      }
    }
  };

  const resetDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
    setDeleteConfirmationStep(0);
  };

  // Shipping Address Component
  const ShippingAddress: React.FC<{ token: string }> = ({ token }) => {
    const [address, setAddress] = useState<Partial<Address>>({ address_type: 'SHIPPING' });
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
      fetchShippingAddress();
    }, [token]);

    const fetchShippingAddress = async () => {
      try {
        const response = await getAddressDetails(token);
        const shippingAddress = response.data.find(addr => addr.address_type === 'SHIPPING');
        if (shippingAddress) {
          setAddress({ ...shippingAddress, address_type: 'SHIPPING' });
        } else {
          setAddress({ address_type: 'SHIPPING' });
        }
        setError('');
      } catch (err) {
        setError('Failed to fetch shipping address');
        setSuccessMessage('');
      }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setAddress(prevAddress => ({
        ...prevAddress,
        [name]: value,
      }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setError('');
      setSuccessMessage('');
      try {
        const updatedAddress = { ...address, address_type: 'SHIPPING' };
        await updateAddressDetails(token, updatedAddress);
        setSuccessMessage('Shipping address updated successfully');
      } catch (err) {
        setError('Failed to update shipping address');
      }
    };

    return (
      <div className="registration-form-container">
        <h2>Shipping Address</h2>
        {successMessage && <p className="success-message">{successMessage}</p>}
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="street_address">Street Address:</label>
            <input
              type="text"
              id="street_address"
              name="street_address"
              value={address.street_address || ''}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="city">City:</label>
            <input
              type="text"
              id="city"
              name="city"
              value={address.city || ''}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="state_or_province">State/Province:</label>
            <input
              type="text"
              id="state_or_province"
              name="state_or_province"
              value={address.state_or_province || ''}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="postal_code">Postal Code:</label>
            <input
              type="text"
              id="postal_code"
              name="postal_code"
              value={address.postal_code || ''}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="country">Country:</label>
            <input
              type="text"
              id="country"
              name="country"
              value={address.country || ''}
              onChange={handleChange}
              required
            />
          </div>
          <input type="hidden" name="address_type" value="SHIPPING" />
          <button type="submit">Update Shipping Address</button>
        </form>
      </div>
    );
  };

  // Billing Address Component
  const BillingAddress: React.FC<{ token: string }> = ({ token }) => {
    const [address, setAddress] = useState<Partial<Address>>({ address_type: 'BILLING' });
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      fetchBillingAddress();
    }, [token]);

    const fetchBillingAddress = async () => {
      setLoading(true);
      try {
        const response = await getAddressDetails(token);
        const billingAddress = response.data.find(addr => addr.address_type === 'BILLING');
        if (billingAddress) {
          setAddress({ ...billingAddress, address_type: 'BILLING' });
        } else {
          setAddress({ address_type: 'BILLING' });
        }
        setError('');
        setSuccessMessage('');
      } catch (err) {
        setError('Failed to fetch billing address');
        setSuccessMessage('');
      } finally {
        setLoading(false);
      }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setAddress(prevAddress => ({
        ...prevAddress,
        [name]: value,
      }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setError('');
      setSuccessMessage('');
      setLoading(true);
      try {
        const updatedAddress = { ...address, address_type: 'BILLING' };
        await updateAddressDetails(token, updatedAddress);
        setSuccessMessage('Billing address updated successfully');
      } catch (err) {
        setError('Failed to update billing address');
        setSuccessMessage('');
      } finally {
        setLoading(false);
      }
    };

    return (
      <div className="registration-form-container">
        <h2>Billing Address</h2>
        {loading && <p>Loading...</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit}>
          <input type="hidden" name="address_type" value="BILLING" />
          <div className="form-group">
            <label htmlFor="street_address">Street Address:</label>
            <input
              type="text"
              id="street_address"
              name="street_address"
              value={address.street_address || ''}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="city">City:</label>
            <input
              type="text"
              id="city"
              name="city"
              value={address.city || ''}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="state_or_province">State/Province:</label>
            <input
              type="text"
              id="state_or_province"
              name="state_or_province"
              value={address.state_or_province || ''}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="postal_code">Postal Code:</label>
            <input
              type="text"
              id="postal_code"
              name="postal_code"
              value={address.postal_code || ''}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="country">Country:</label>
            <input
              type="text"
              id="country"
              name="country"
              value={address.country || ''}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" disabled={loading}>
            {loading ? 'Updating...' : 'Update Billing Address'}
          </button>
        </form>
      </div>
    );
  };

  if (loading) return <div className="loading">Loading...</div>;
  return (
    <div className={styles.accountPage}>
      <h1 className={styles.pageTitle}>Your UShop.bid Account</h1>
      {successMessage && <p className={styles.successMessage}>{successMessage}</p>}
      {error && <p className={styles.errorMessage}>{error}</p>}

      <div className={styles.accountSections}>
        <div className={styles.sectionTabs}>
          {['accountDetails', 'credits', 'wonAuctions', 'biddingHistory'].map((section) => (
            <button
              key={section}
              className={`${styles.sectionTab} ${activeSection === section ? styles.active : ''}`}
              onClick={() => handleSectionChange(section)}
            >
              {section.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
            </button>
          ))}
        </div>

        <div className={styles.sectionContent}>
          {activeSection === 'accountDetails' && (
            <div>
              <h2>Account Details</h2>
              <div className={styles.subsectionTabs}>
                {['personalInfo', 'shippingAddress', 'billingAddress', 'accountActions'].map((subSection) => (
                  <button
                    key={subSection}
                    className={`${styles.subsectionTab} ${activeSubSection === subSection ? styles.active : ''}`}
                    onClick={() => handleSubSectionChange(subSection)}
                  >
                    {subSection.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                  </button>
                ))}
              </div>

              {activeSubSection === 'personalInfo' && (
                <form onSubmit={handleAccSubmit} className={styles.accountForm}>
                  <div className={styles.formGroup}>
                    <label htmlFor="first_name">First Name:</label>
                    <input
                      type="text"
                      id="first_name"
                      name="first_name"
                      value={user.first_name || ''}
                      onChange={handleChange}
                      placeholder="First Name"
                      required
                    />
                  </div>
                  <div className={styles.formGroup}>
                    <label htmlFor="last_name">Last Name:</label>
                    <input
                      type="text"
                      id="last_name"
                      name="last_name"
                      value={user.last_name || ''}
                      onChange={handleChange}
                      placeholder="Last Name"
                      required
                      className={styles.input}
                    />
                  </div>
                  <div className={styles.formGroup}>
                    <label htmlFor="email">Email:</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={user.email || ''}
                      onChange={handleChange}
                      placeholder="Email"
                      required
                      className={styles.input}
                    />
                  </div>
                  <div className={styles.formGroup}>
                    <label htmlFor="phone">Phone:</label>
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      value={user.phone || ''}
                      onChange={handleChange}
                      placeholder="Phone"
                      className={styles.input}
                    />
                  </div>
                  <div className={styles.formGroup}>
                    <label htmlFor="password">New Password:</label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      onChange={handleChange}
                      placeholder="New Password"
                      className={styles.input}
                    />
                  </div>
                  <button type="submit" className={styles.submitButton} disabled={loading}>
                    {loading ? 'Updating...' : 'Update Account'}
                  </button>
                </form>
              )}

              {activeSubSection === 'shippingAddress' && (
                <ShippingAddress token={token} />
              )}

              {activeSubSection === 'billingAddress' && (
                <BillingAddress token={token} />
              )}

              {activeSubSection === 'accountActions' && (
                <div>
                  <button
                    className={styles.deleteButton}
                    onClick={() => setShowDeleteConfirmation(true)}
                  >
                    Delete Account
                  </button>
                  <button
                    className={styles.logoutButton}
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          )}

          {activeSection === 'credits' && (
            <CreditsSection
              token={token}
              currentCredits={user.credits || 0}
              onCreditsAdded={(newCredits) => setUser(prev => ({ ...prev, credits: newCredits }))}
            />
          )}

          {activeSection === 'wonAuctions' && (
            <WonAuctions token={token} userId={user.id || 0} />
          )}

          {activeSection === 'biddingHistory' && (
            <BiddingHistory token={token} />
          )}
        </div>
      </div>

      {showDeleteConfirmation && (
        <div className={styles.confirmationModal}>
          <h3>Are you sure you want to delete your account?</h3>
          <p>This action cannot be undone. All your data will be permanently deleted.</p>
          <p>Confirmation step: {deleteConfirmationStep + 1} of 2</p>
          <button
            className={styles.deleteButton}
            onClick={handleDeleteAccount}
          >
            {deleteConfirmationStep === 0 ? 'Yes, I want to delete my account' :
              deleteConfirmationStep === 1 ? 'Yes, I confirm account deletion' :
                'Delete my account permanently'}
          </button>
          <button
            className={styles.cancelButton}
            onClick={resetDeleteConfirmation}
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

export default AccountPage;
